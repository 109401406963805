<template>
  <div class="vc-w-100 vc-flex-center vc-schedule-list-box pl-4 pr-4">
    <div class="vc-back-home text-start mb-2">
      <v-btn @click="$router.push({ name: 'Home' })" color="primary">
        <v-icon size="25px" left color="white">mdi-home</v-icon>
        <div class="vc-text-bold">home</div>
      </v-btn>
    </div>
    <v-card width="100%" max-width="910px" class="pa-3 ml-4 mr-4 ">
      <div v-if="!user" class="vc-column-center vc-w-100 pa-3">
        <div class="vc-font-20 vc-text-primary vc-text-bold">
          Você não está logado!
        </div>
        <div class="vc-font-14 vc-text-primary mt-3">
          <v-alert type="info" color="primary" border="left" text class="mb-0">
            Para <strong> realizar seu agendamento</strong>, por favor, efetue o
            <strong>login</strong>!
          </v-alert>
        </div>
        <div class="vc-row-center mt-5">
          <v-btn @click="openLoginDialog" color="primary"
            ><div class="vc-text-bold">fazer login</div></v-btn
          >
        </div>
      </div>

      <div
        v-else-if="user && schedulePatientList.length > 0"
        class="vc-w-100 vc-schedule-list-box-content pa-3"
      >
        <div class="vc-w-100 vc-font-20 text-center">
          Agendamentos
        </div>
        <div
          class="vc-w-100 vc-flex-center mt-5"
          v-for="(schedulePatient, i) in schedulePatientList"
          :key="`${i}${schedulePatient.id}`"
        >
          <card-patient-schedule
            @openRemoveDialog="openRemoveDialog"
            @openUpdateDialog="openUpdateDialog"
            @setFreeTime="setFreeTime"
            :schedule="schedulePatient"
          />
        </div>

        <v-dialog
          v-if="currentSchedule && removeDialog > 0"
          max-width="400px"
          v-model="removeDialog"
        >
          <v-card>
            <v-card-title class="text-h5">
              Cancelar agendamento
            </v-card-title>
            <v-card-text>
              Deseja cancelar o agendamento do paciente
              <strong>{{ currentSchedule.patient.people.name }}</strong>
              às <strong>{{ currentSchedule.time }}</strong> ?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="removeDialog = false">
                fechar
              </v-btn>
              <v-btn color="primary" @click="removePatientSchedule">
                <div class="vc-text-bold">confirmar</div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog max-width="400px" v-model="updateDialog" persistent>
          <v-card>
            <v-card-title class="text-h5">
              Atualizar agendamento
            </v-card-title>
            <v-card-text>
              <v-form
                v-model="valid"
                ref="updateSchedulePatient"
                lazy-validation
              >
                <v-row class="ma-0">
                  <v-col cols="12" class="pa-0">
                    <div class="vc-text-bold">Paciente</div>
                    <v-text-field
                      v-model="modelScheduling.patient_name"
                      label="Digite aqui"
                      solo
                      disabled
                      prepend-inner-icon="mdi-account"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <div class="vc-text-bold">Horário da Consulta</div>
                    <v-text-field
                      v-model="modelScheduling.time"
                      label="Digite aqui"
                      solo
                      disabled
                      prepend-inner-icon="mdi-clock"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="pa-0">
                    <div class="vc-text-bold">Novo Horário da Consulta</div>
                    <v-autocomplete
                      v-model="newFreeTime"
                      :items="modelScheduling.freeTime"
                      label="Digite aqui"
                      solo
                      placeholder="Ex.: 13:00:00"
                      hide-details
                      :rules="[rules.required]"
                    ></v-autocomplete>
                    <div v-if="!valid" class="red--text vc-font-12">
                      *Este campo é obrigatório
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="updateDialog = false">
                fechar
              </v-btn>
              <v-btn
                :disabled="!valid"
                color="primary"
                @click="updatePatientSchedule"
              >
                <div class="vc-text-bold">confimar</div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12" class="align-text">
            <h3>
              Você ainda não possui historico de agendamento, que tal começarmos
              agora?
            </h3>
          </v-col>
        </v-row>
        <v-container>
          <img src="@/assets/images/Search.svg" alt="" />
        </v-container>
      </div>
    </v-card>
  </div>
</template>

<script>
import CardPatientSchedule from '@/components/scheduling/CardPatientSchedule';
import ls from 'local-storage';
export default {
  name: 'schedule-list',
  components: {
    CardPatientSchedule,
  },
  data: () => ({
    valid: true,
    removeDialog: false,
    updateDialog: false,
    currentSchedule: null,
    newFreeTime: '',
    schedulePatientList: [],
    currentScheduleFreeTime: [],
    rules: {
      required: (value) => !!value || 'Campo obrigatório!',
    },
    modelScheduling: {
      patient_name: '',
      patient_id: '',
      schedule_id: '',
      time: '',
      observation: '',
      return_id: '',
      status: '',
      freeTime: [],
      date: null,
    },
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentCompany() {
      return this.$store.getters.currentCompany;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let body = {
        patient_id: ls.get('patientId'),
        people_id: ls.get('peopleId'),
      };
      this.$store.dispatch('getAllPatientSchedule', body).then((resp) => {
        console.log('resp', resp);
        this.schedulePatientList = resp.sort(this.sortArray);
      });
    },
    sortArray(a, b) {
      if (a.created_at > b.created_at) {
        return -1;
      }
      if (a.created_at < b.created_at) {
        return 1;
      }
      return 0;
    },

    openLoginDialog() {
      this.$store.commit('SET_AUTH_MODAL', true);
      this.$router.push({ name: 'Home' });
    },
    openRemoveDialog(val) {
      this.removeDialog = true;
      this.currentSchedule = val;
    },
    openUpdateDialog(val) {
      let body = {
        company_id: this.currentCompany.id,
        schedule_id: val.schedule_id,
      };
      this.$store.dispatch('getScheduleAvaliable', body).then((resp) => {
        this.updateDialog = true;
        this.currentSchedule = val;
        this.modelScheduling.patient_name = val.patient.people.name;
        this.modelScheduling.patient_id = val.patient_id;
        this.modelScheduling.schedule_id = val.id;
        this.modelScheduling.time = val.time;
        this.modelScheduling.observation = val.observation;
        this.modelScheduling.return_id = val.return_id;
        this.modelScheduling.status = val.status;
        this.modelScheduling.freeTime = resp.free_time.filter(item => item !== val.time);
        this.modelScheduling.date = resp.date;
      });
    },
    setFreeTime(val) {
      this.currentScheduleFreeTime = val;
    },
    removePatientSchedule() {
      let body = {
        id: this.currentSchedule.id,
        status: 'CALLEDOFF',
        date: this.currentSchedule.schedule.date,
        time: this.currentSchedule.time,
      };
      this.$store
        .dispatch('removePatientSchedule', body)
        .then(() => {
          this.$store.commit('SET_NOTIFICATION', {
            type: 'success',
            message: 'Agendamento do paciente cancelado com sucesso!',
          });
          this.removeDialog = false;
          this.init();
        })
        .catch(() => {
          this.$store.commit('SET_NOTIFICATION', {
            type: 'error',
            message: 'Não foi possível cancelar o agendamento do paciente!',
          });
        });
    },
    updatePatientSchedule() {
      if (this.$refs.updateSchedulePatient.validate()) {
        this.modelScheduling.time = this.newFreeTime;
        let body = {
          id: this.currentSchedule.id,
          body: this.modelScheduling,
        };
        this.$store
          .dispatch('updatePatientSchedule', body)
          .then(() => {
            this.$store.commit('SET_NOTIFICATION', {
              type: 'success',
              message: 'Agendamento do paciente atualizado com sucesso!',
            });
            this.updateDialog = false;
            this.init();
          })
          .catch((error) => {
            var messageError = '';
            switch (error.response.data.message) {
              case 'patient is already scheduled on this time':
              messageError = 'O Paciente já está agendado para esse horário';
                break;
              default:
                messageError = 'Não foi possível atualizar o agendamento do paciente!';
            }
            this.$store.commit('SET_NOTIFICATION', {
              type: 'danger',
              message: messageError,
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #2e5f96; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: solid 3px white;
  // border: 3px solid orange; /* creates padding around scroll thumb */
}

.align-text {
  text-align: center;
  justify-content: center;
  align-content: center;
  display: flex;
  margin-top: 50px;
}
</style>
