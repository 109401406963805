import Paginator from '../_paginator';
import http from '../api';
import httpAdm from '../apiAuth';

let getAll = (config) => {
  return new Paginator({
    url: 'patient-dependent',
    config: config,
  });
};

let getPatient = (data) => {
  return http.get(`patient-check/${data.email}&${data.company_id}`);
};

let updatePatient = (data) => {
  return http.put(`patients/${data.id}`, data);
};

let removePatient = (data) => {
  return http.delete(`patients/${data}`);
};

let patientVerify = (data) => {
  return http.post('patient-verify', data);
};

let savePatient = (data) => {
  return http.post('patients', data);
};
let savePatientVCP = (data) => {
  return http.post('vcp-patients', data);
};
let cadasterUser = (data) => {
  return httpAdm.post('user-generic', data);
};

let getPatientDependent = (data) => {
  return http.get(`patient-dependent/${data.dependent_id}`);
};
export default {
  getAll,
  patientVerify,
  savePatient,
  savePatientVCP,
  getPatient,
  updatePatient,
  cadasterUser,
  removePatient,

  // dependent
  getPatientDependent,
};
