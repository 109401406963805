<template>
  <v-app>
    <div class=" fill-height">
      <router-view> </router-view>
    </div>
  </v-app>
</template>

<script>
import ls from 'local-storage';

export default {
  name: 'App',

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    notification() {
      return this.$store.getters.notification;
    },
    currentCompany() {
      return this.$store.getters.currentCompany;
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    notification(val) {
      if (val.type.toUpperCase() === 'SUCCESS') {
        this.$toast.success(`${val.message}`, {
          position: 'top-right',
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: 'button',
          icon: true,
          rtl: false,
        });
      } else {
        this.$toast.error(`${val.message}`, {
          position: 'top-right',
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: 'button',
          icon: true,
          rtl: false,
        });
      }
    },
  },
  methods: {
    init() {
      this.$store
        .dispatch('getCompany', this.$route.params.id)
        .then((resp) => {
          if (ls.get('currentCompany')) {
            if (ls.get('currentCompany').id != resp.id) {
              ls.clear();
              ls.set('currentCompany', resp);
            }
            // ls.get('currentCompany').id === resp.id ? '' : ls.clear();
          } else {
            ls.set('currentCompany', resp);
          }
          this.$store.commit('SET_CURRENT_COMPANY', resp);
        })
        .catch(() => {
          console.log('app.vue')
          ls.clear();
          if (this.currentRouteName != 'change-password') {
            this.$router.push('error-404');
          }
        });
    },
  },
};
</script>
<style scoped></style>
