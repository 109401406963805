import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

// mixin
import './mixin/index'

// scss
import './assets/scss/main.scss'
// mask
import VueMask from 'v-mask'
Vue.use(VueMask);
// toast notification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});

Vue.config.productionTip = false

store.dispatch('initAuth')

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
