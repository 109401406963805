import Vue from 'vue'

Vue.mixin({
    methods: {
        formatQueryParams(data) {
            let queryString = ''
            for (let [key, value] of Object.entries(data)) {
                if (value) {
                    if (queryString === '') {
                        queryString = `${queryString}?${key}=${value}`
                    } else {
                        queryString = `${queryString}&${key}=${value}`
                    }
                }
            }
            return queryString
        },

        reverseDateString(date) {
            return date.split('-').reverse().join('-');
        },
        reverseDateStringTwo(str) {
            return str
                .split('/')
                .reverse()
                .join('/');
        },
        replaceDateString(str) {
            return str
                .split('-')
                .join('/');
        },
        replaceDateStringTwo(str) {
            return str
                .split('/')
                .join('-');
        },
    },
})