<template>
  <v-form
    ref="formDateHour"
    lazy-validation
    class="vc-form-scheduling vc-w-100"
  >
    <v-alert
      v-if="schedules.length > 0 && !loading"
      color="primary"
      dense
      text
      type="info"
      class="text-center"
    >
      Para <strong>selecionar</strong> um agendamento desejado, por favor,
      <strong>escolha</strong> um <strong>horário</strong> e clique em
      <strong>'Selecionar'</strong>.
    </v-alert>
    <v-alert
      v-else-if="consult.doctor_id && !loading || !loading && schedules.length < 1"
      color="error"
      dense
      text
      type="info"
      class="text-center"
    >
      A <strong> Especialidade </strong> escolhida não possui <strong>profissionais da saúde</strong> ou
      não possui <strong>horários livres.</strong>
    </v-alert>
    <!-- <v-alert
      v-else
      color="info"
      dense
      text
      type="info"
      class="text-center"
    >
      Para ver os dias disponiveis da agenda selecione um <strong
        >Profissional da saúde</strong
      >
      acima!
    </v-alert> -->
    <v-row class="ma-0 vc-w-100">
      <v-col
        cols="12"
        v-for="(schedule, i) in schedules"
        :key="`${i}${schedule.name_doctor}`"
        class="vc-flex-center vc-w-100 pa-0 mt-3 mb-2"
      >
        <card-schedule :schedule="schedule" @setSchedule="setSchedule" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import CardSchedule from '../CardSchedule';
import { format } from 'date-fns';
import ls from 'local-storage';
import moment from 'moment';

export default {
  name: 'form-date-hour',
  props: {
    consult: { type: Object, default: () => {} },
  },
  data: () => ({
    loading: false,
    firstDoc: true,
    schedules: [],
    schedulesList: [],
    modelSchedule: {
      schedule: {
        schedule_id: '',
        time: '',
        observation: '',
        return_id: '',
        status: '',
      },
      scheduling: '',
    },
  }),
  components: {
    CardSchedule,
  },
  watch: {
    'consult.doctor_id'() {
      this.filter();
    },
    'consult.date_from'() {
      this.consult.date_to ? this.filter() : '';
    },
    'consult.date_to'() {
      this.consult.date_from ? this.filter() : '';
    },
  },
  computed: {
    currentCompany() {
      return this.$store.getters.currentCompany;
    },
  },
  mounted() {
    this.filter();
  },
  methods: {
    filter() {
      this.loading = true;
      this.firstDoc = false;
      // if (!this.firstDoc && this.consult.doctor_id) {
        let body = this.formatQueryParams(this.consult);
        this.$store.dispatch('getScheduleList', body).then((resp) => {
          resp.sort( this.compare );
          this.schedules = resp;
          this.formatDate();
          if(this.currentCompany.multiple_events){
            this.showAllDates();
          }
          this.loading = false;
        }).catch(()=>{
          this.loading = false;
        });
      // }
      
    },
    compare( a, b ) {
  if ( a.date < b.date ){
    return -1;
  }
  if ( a.date > b.date ){
    return 1;
  }
  return 0;
},
    setSchedule(val) {
      this.modelSchedule.schedule.schedule_id = val.schedule.id;
      this.modelSchedule.schedule.time = val.time;
      this.modelSchedule.scheduling = `${val.schedule.date} às ${val.time}`;
      this.$store.commit('SET_CURRENT_SCHEDULE', this.modelSchedule);
      ls.set('currentSchedule', this.modelSchedule);
      this.$emit('nextStep');
    },
    formatDate() {
      this.schedules = this.schedules.map((item) => {
        let date = new Date(item.date);
        item.date = format(date, 'dd/MM/yyyy');
        return item;
      });
    },
    showAllDates() {
      this.schedules = this.schedules.map((obj) => {
        let all_sheduled_times = obj.schedules.map(a => a.time)
        obj.free_time = [...obj.free_time, ...(obj.schedules.filter(({time}, index) => !all_sheduled_times.includes(time, index + 1))).map(a=>a.time)]
        obj.free_time = obj.free_time.sort((a,b) => moment('2022-01-01 ' + a).diff(moment('2022-01-01 ' + b)))
        return obj;
      });

      
    },
  },
};
</script>
