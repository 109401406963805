<template>
  <v-card class="vc-stepper pa-5" v-if="hideMenu == false">
    <div class="vc-stepper-items pa-5">
      <div class="vc-stepper-item mt-1 mb-6">
        <v-btn
          class="vc-stepper-button vc-button-height"
          height="50px"
          :color="stepItemStatus[0].color"
          @click="setStep(stepItemStatus[0].stepId)"
        >
          <div class="vc-font-20 vc-font-bolder white--text">1</div>
        </v-btn>
        <div class="vc-stepper-item-identifier ml-2">
          <div :class="stepItemStatus[0].class">Convênio</div>
          <div
            v-if="currentConvenant"
            class="vc-text-darkGrey vc-font-14 vc-text-bold"
          >
            {{ currentConvenant.name }}
          </div>
        </div>
      </div>

      <div class="vc-stepper-item mt-1 mb-6">
        <v-btn
          :class="
            stepItemStatus[1].disabled
              ? 'vc-button-height'
              : 'vc-stepper-button'
          "
          height="50px"
          :color="stepItemStatus[1].color"
          :disabled="stepItemStatus[1].disabled"
          @click="setStep(stepItemStatus[1].stepId)"
        >
          <div
            :class="
              stepItemStatus[1].disabled
                ? 'vc-font-20 vc-font-bolder vc-text-grey'
                : 'vc-font-20 vc-font-bolder white--text'
            "
          >
            2
          </div>
        </v-btn>
        <div class="vc-stepper-item-identifier ml-2">
          <div :class="stepItemStatus[1].class">Especialidade</div>
          <div class="vc-text-darkGrey vc-font-14 vc-text-bold">
            {{ currentSpecialty.name }}
          </div>
        </div>
      </div>

      <!-- <div class="vc-stepper-item mt-1 mb-6">
        <v-btn
          :class="
            stepItemStatus[2].disabled
              ? 'vc-button-height'
              : 'vc-stepper-button'
          "
          height="50px"
          :color="stepItemStatus[2].color"
          :disabled="stepItemStatus[2].disabled"
          @click="setStep(stepItemStatus[2].stepId)"
        >
          <div
            :class="
              stepItemStatus[2].disabled
                ? 'vc-font-20 vc-font-bolder vc-text-grey'
                : 'vc-font-20 vc-font-bolder white--text'
            "
          >
            3
          </div>
        </v-btn>
        <div class="vc-stepper-item-identifier ml-2">
          <div :class="stepItemStatus[2].class">Clínica</div>
          <div class="vc-text-darkGrey vc-font-14 vc-text-bold">
            {{ currentScheduleCompany.name }}
          </div>
        </div>
      </div> -->

      <div class="vc-stepper-item mt-1 mb-6">
        <v-btn
          :class="
            stepItemStatus[2].disabled
              ? 'vc-button-height'
              : 'vc-stepper-button'
          "
          height="50px"
          :color="stepItemStatus[2].color"
          :disabled="stepItemStatus[2].disabled"
          @click="setStep(stepItemStatus[2].stepId)"
        >
          <div
            :class="
              stepItemStatus[2].disabled
                ? 'vc-font-20 vc-font-bolder vc-text-grey'
                : 'vc-font-20 vc-font-bolder white--text'
            "
          >
            3
          </div>
        </v-btn>
        <div class="vc-stepper-item-identifier ml-2">
          <div :class="stepItemStatus[2].class">
            Data e Hora
          </div>
          <div class="vc-text-darkGrey vc-font-14 vc-text-bold">
            {{ currentSchedule.scheduling }}
          </div>
        </div>
      </div>

      <div class="vc-stepper-item mt-1 mb-6">
        <v-btn
          :class="
            stepItemStatus[3].disabled
              ? 'vc-button-height'
              : 'vc-stepper-button'
          "
          height="50px"
          :color="stepItemStatus[3].color"
          :disabled="stepItemStatus[3].disabled"
          @click="setStep(stepItemStatus[3].stepId)"
        >
          <div
            :class="
              stepItemStatus[3].disabled
                ? 'vc-font-20 vc-font-bolder vc-text-grey'
                : 'vc-font-20 vc-font-bolder white--text'
            "
          >
            4
          </div>
        </v-btn>
        <div class="vc-stepper-item-identifier ml-2">
          <div :class="stepItemStatus[3].class">Paciente</div>
          <div class="vc-text-darkGrey vc-font-14 vc-text-bold">
            {{ currentPatient.username }}
          </div>
        </div>
      </div>

      <div class="vc-stepper-item mt-1 mb-4">
        <v-btn
          :class="
            stepItemStatus[4].disabled
              ? 'vc-button-height'
              : 'vc-stepper-button'
          "
          height="40px"
          :color="stepItemStatus[4].color"
          :disabled="stepItemStatus[4].disabled"
          @click="setStep(stepItemStatus[4].stepId)"
        >
          <div
            :class="
              stepItemStatus[4].disabled
                ? 'vc-font-20 vc-font-bolder vc-text-grey'
                : 'vc-font-20 vc-font-bolder white--text'
            "
          >
            5
          </div>
        </v-btn>
        <div class="vc-stepper-item-identifier ml-2">
          <div :class="stepItemStatus[4].class">
            Confirmação
          </div>
          <!-- <div class="vc-text-grey  vc-font-14 vc-text-bold">Bradesco</div> -->
        </div>
      </div>
    </div>

    <img
      class="vc-stepper-logo"
      src="@/assets/images/logo-vclinic_1.png"
      width="110px"
    />
  </v-card>
</template>

<script>
export default {
  name: 'stepper',
  props: {
    step: { type: Number, default: 1 },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      classSelected: 'vc-text-primary vc-font-18 vc-text-bold',
      classAlreadyPassed: 'vc-text-blue vc-font-18 vc-text-bold',
      classDisabled: 'vc-text-grey vc-font-18 vc-text-bold',
      stepItemStatus: [
        {
          stepId: 1,
          class: 'vc-text-primary vc-font-18 vc-text-bold',
          color: 'primary',
          disabled: false,
        },
        {
          stepId: 2,
          class: 'vc-text-grey vc-font-18 vc-text-bold',
          color: 'vcBlue',
          disabled: true,
        },
        {
          stepId: 3,
          class: 'vc-text-grey vc-font-18 vc-text-bold',
          color: 'vcBlue',
          disabled: true,
        },
        {
          stepId: 4,
          class: 'vc-text-grey vc-font-18 vc-text-bold',
          color: 'vcBlue',
          disabled: true,
        },
        {
          stepId: 5,
          class: 'vc-text-grey vc-font-18 vc-text-bold',
          color: 'vcBlue',
          disabled: true,
        },
        {
          stepId: 6,
          class: 'vc-text-grey  vc-font-18 vc-text-bold',
          color: 'vcBlue',
          disabled: true,
        },
      ],
    };
  },
  created() {
    if(this.windowWidth > 688){
      this.$store.commit('HIDE_MENU', false);
    }else{
      this.$store.commit('HIDE_MENU', true);

    }
  },
  computed: {
    hideMenu() {
      return this.$store.getters.hideMenu;
    },
    currentConvenant() {
      return this.$store.getters.currentConvenant;
    },
    currentSpecialty() {
      return this.$store.getters.currentSpecialty;
    },
    currentScheduleCompany() {
      return this.$store.getters.currentScheduleCompany;
    },
    currentSchedule() {
      return this.$store.getters.currentSchedule;
    },
    currentPatient() {
      return this.$store.getters.currentPatient;
    },
  },
  watch: {
    step(newVal, oldVal) {
      newVal > oldVal ? this.nextStep() : this.backStep();
    },
  },
  methods: {
    nextStep() {
      // set late step
      this.stepItemStatus[this.step - 2].disabled = false;
      this.stepItemStatus[this.step - 2].class = this.classAlreadyPassed;
      this.stepItemStatus[this.step - 2].color = 'vcBlue';

      // set current step
      this.stepItemStatus[this.step - 1].disabled = false;
      this.stepItemStatus[this.step - 1].class = this.classSelected;
      this.stepItemStatus[this.step - 1].color = 'primary';
    },
    backStep() {
      // set current step
      this.stepItemStatus[this.step - 1].disabled = false;
      this.stepItemStatus[this.step - 1].class = this.classSelected;
      this.stepItemStatus[this.step - 1].color = 'primary';

      // set late step
      this.stepItemStatus[this.step].disabled = true;
      this.stepItemStatus[this.step].class = this.classDisabled;
      this.stepItemStatus[this.step].color = 'vcBlue';
    },
    setStep(step) {
      if (step != this.step) {
        this.$emit('setStep', step);
        this.stepItemStatus[step - 1].disabled = false;
        this.stepItemStatus[step - 1].class = this.classSelected;
        this.stepItemStatus[step - 1].color = 'primary';
        for (let i = step; i <= this.stepItemStatus.length - 1; i++) {
          this.stepItemStatus[i].color = 'vcBlue';
          this.stepItemStatus[i].disabled = true;
          this.stepItemStatus[i].class = this.classDisabled;
        }
      }
    },
  },
};
</script>
