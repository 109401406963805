import http from '../api'
import Paginator from '../_paginator'

let getAll = (config) => {
    return new Paginator({
        url: 'specialist',
        config: config
    });
}

let insertDoctorSpecialty = (data) => {
    return http.post('doctors-specialists', data)
}

let getAllSpecialties = (data) => {
    return http.get(`specialist/${data}&page=1&perPage=1000`)
}

let getSpecialty = (specialty_id) => {
    return http.get(`specialist/${specialty_id}`)
}

let saveSpecialty = (data) => {
    return http.post('specialist', data)
}

let updateSpecialty = (specialty_id, data) => {
    return http.put(`specialist/${specialty_id}`, data)
}

let removeSpecialty = (specialty_id) => {
    return http.delete(`specialist/${specialty_id}`)
}

export default {
    getAll,
    insertDoctorSpecialty,
    getSpecialty,
    getAllSpecialties,
    saveSpecialty,
    removeSpecialty,
    updateSpecialty,
}