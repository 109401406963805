import http from '../api'

let getAllDoctorSchedule = (company_id) => {
    return http.get(`schedule/?company_id=${company_id}`)
}

let getScheduleTypes = () => {
    return http.get(`schedule-type`);
}

let getDoctorSchedule = (doctor_id) => {
    return http.get(`schedule/?doctor_id=${doctor_id}`)
}

let getScheduleList = (data) => {
    return http.get(`schedule-list-patient/${data}`)
}
let getScheduleAvaliable = (data) => {
    return http.get(`schedule-avaliable/?company_id=${data.company_id}&schedule_id=${data.schedule_id}`)
}
// scale doctor
let getAllScales = (data) => {
    return http.get(`schedule-generic/${data}`)
}

// let getAllScales = (data) => {
//     return http.get(`schedule/${data}`)
// }

let saveDoctorScale = (data) => {
    return http.post('schedule', data)
}

let updateDoctorScale = (data) => {
    return http.put(`schedule/${data.schedule_id}`, data.modelScale)
}

let removeDoctorScale = (schedule_id) => {
    return http.delete(`schedule/${schedule_id}`)
}

// patient
let getPatientSchedule = (data) => {
    return http.get(`events${data}`);
}

let getAllPatientSchedule = (data) => {
    return http.get(`event-patients/${data.people_id}&${data.patient_id}`)
}

let savePatientSchedule = (data) => {
    return http.post('event-patient', data);
}

let updatePatientSchedule = (data) => {
    return http.put(`event-patient/${data.id}`, data.body);
}

let removePatientSchedule = (data) => {
    return http.put(`event-patient/${data.id}`,data);
}

let reserveSchedule = (data) => {
    return http.post(`schedule-reserve`, data);
}

export default {
    getScheduleTypes,
    getAllDoctorSchedule,
    getDoctorSchedule,
    getScheduleList,

    getAllPatientSchedule,
    getPatientSchedule,
    savePatientSchedule,
    updatePatientSchedule,
    removePatientSchedule,

    //scale
    getAllScales,
    getScheduleAvaliable,
    saveDoctorScale,
    updateDoctorScale,
    removeDoctorScale,

    reserveSchedule,
}