<template>
  <v-card width="100%" elevation="2" class="vc-card-patient-schedule pa-0">
    <div class="vc-scheduling-card-header pa-2">
      <v-icon size="24px" color="white">mdi-calendar</v-icon>
      <div class="white--text  vc-font-16 ml-2">
        {{ schedule.date }} - {{ day }}
      </div>
    </div>

    <div
      class="vc-scheduling-card-body vc-column-center vc-w-100 pt-8 pb-8 pl-3 pr-3"
    >
      <v-row class="vc-w-100 ma-0 vc-row-center vc-scheduling-card-body-info">
        <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="pa-2 vc-column-center">
          <v-icon size="60px" color="primary">mdi-account-circle</v-icon>
          <div class="vc-text-bold">
            {{ schedule.doctor.people.name }}
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="pa-2">
          <div class="text-center mb-1">Selecione o horário desejado:</div>
          <v-row v-if="currentCompany.schedule_time_group" class="vc-w-100 ma-0 d-flex">
            <v-col cols="12">
              <v-btn @click="reserveSchedule(schedule.start_time)" block>
                {{schedule.start_time + ' - ' + schedule.end_time}}
              </v-btn>
            </v-col>
            <v-col cols="12" align="center">
              <small class="text-center mb-2">Atenção: o atendimento será realizado por ordem de chegada</small>
            </v-col>
          </v-row>
          <v-row v-else class="vc-w-100 ma-0 d-flex ">
            <v-col
              v-for="(time, i) in schedule.free_time"
              :key="`${i}${time}`"
              class="px-2 pt-2 pb-2"
            >
              <v-btn @click="reserveSchedule(time)">
                {{ time }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import ls from 'local-storage';

export default {
  name: 'card-schedule',
  props: {
    schedule: { type: Object, default: () => {} },
  },
  data: () => ({
    timeSelected: '',
    day: '',
    weekDays: {
      0: {
        name: 'Domingo',
      },
      1: {
        name: 'Segunda-feira',
      },
      2: {
        name: 'Terça-feira',
      },
      3: {
        name: 'Quarta-feira',
      },
      4: {
        name: 'Quinta-feira',
      },
      5: {
        name: 'Sexta-feira',
      },
      6: {
        name: 'Sábado',
      },
    },
  }),
  computed: {
    currentCompany() {
      return this.$store.getters.currentCompany;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let dateIndex = this.schedule.date;
      dateIndex = this.reverseDateStringTwo(dateIndex);
      dateIndex = this.replaceDateStringTwo(dateIndex);
      dateIndex = new Date(dateIndex);
      dateIndex = dateIndex.getUTCDay();
      console.log(this.weekDays[dateIndex].name)
      this.day = this.weekDays[dateIndex].name;
    },
    reserveSchedule(time){
      this.$store
        .dispatch('reserveSchedule', {schedule_id: this.schedule.id, time: time, group: this.currentCompany.schedule_time_group})
        .then((resp) => {
          this.$store.commit('SET_NOTIFICATION', {
            type: 'success',
            message: 'Horário reservado com sucesso!',
          });
          if(this.currentCompany.schedule_time_group){
            this.setSchedule(resp.time)
          }else{
            this.setSchedule(time)
          }
        })
        .catch((error) => {
          var messageError = '';
          switch (error.response.data.message) {
            case 'time taken':
              messageError = 'Horário já Preenchido!';
              break;
            case 'all time taken':
              messageError = 'Intervalo de Horário Lotado!';
              break;
            default:
              messageError = 'Não foi possivél realizar agendamento, horário não está mais disponivel!';
          }
          this.$store.commit('SET_NOTIFICATION', {
            type: 'danger',
            message: messageError,
          });
        });
    },
    setSchedule(time) {
      let doctor = {
        doctor_id: this.schedule.doctor_id,
        name_doctor: this.schedule.doctor.people.name,
      };
      ls.set('currentDoctor', doctor);
      this.$store.commit('SET_CURRENT_DOCTOR', doctor);
      this.$emit('setSchedule', {
        schedule: this.schedule,
        time: time,
        date: this.schedule.date,
      });
    },
  },
};
</script>
