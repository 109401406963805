<template>
  <v-app>
    <Toolbar />
    <v-main class="vc-fadeIn">
      <v-container
        fluid
        fill-height
        class="vc-w-100 main-container primaryLight pa-0"
      >
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Toolbar from "@/layouts/toolbar/Toolbar.vue";

export default {
  name: "main",
  components: {
    Toolbar,
  },
};
</script>
