<template>
  <v-form ref="formPatient" lazy-validation class="vc-form-scheduling vc-w-100">
    <v-row v-if="user" class="ma-0 vc-w-100">
      <v-alert
        v-if="patients.length > 0"
        color="primary"
        dense
        text
        type="info"
        class="text-center"
      >
        Para <strong>selecionar</strong> o paciente desejado, por favor,
        selecione a opção <strong>'Selecionar'</strong> abaixo.
      </v-alert>

      <v-col cols="12" md="6" class="vc-flex-center pa-2 mt-3 mb-2">
        <card-scheduling
          :id="user.id"
          :title="`${user.username} (Você)`"
          icon="mdi-account"
          @action="setPatient(user)"
        />
      </v-col>
      <v-col cols="12" md="6" class="vc-flex-center pa-2 mt-3 mb-2">
        <v-card
          width="100%"
          max-width="550px"
          min-height="80px"
          elevation="2"
          class="pa-0 vc-flex-center"
          @click="depedentModal = true"
        >
          <v-icon color="primary" left>mdi-account-plus</v-icon>
          Adicionar dependente
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-for="(patient, i) in patients"
        :key="`${i}${patient.name}`"
        class="vc-flex-center pa-2 mt-3 mb-2"
      >
        <card-scheduling
          :id="patient.id"
          :title="`${patient.name} (Dependente)`"
          icon="mdi-account"
          type="patient"
          @action="setPatient(patient)"
        />
      </v-col>
    </v-row>

    <v-row v-else class="ma-0 vc-w-100 vc-flex-center">
      <div class="vc-column-center vc-w-100 pa-3">
        <div class="vc-font-20 vc-text-primary vc-text-bold">
          Você não está logado!
        </div>
        <div class="vc-font-14 vc-text-primary mt-3">
          <v-alert type="info" color="primary" border="left" text class="mb-0">
            Para <strong> realizar seu agendamento</strong>, por favor, efetue o
            <strong>login</strong>!
          </v-alert>
        </div>
        <div class="vc-font-14 vc-text-primary mt-3">
          <v-alert type="info" color="primary" border="left" text class="mb-0">
            Ao se <strong>cadastrar</strong>, um e-mail será enviado com as <strong>credenciais</strong> necessárias para efetuar o <strong>login!</strong>
          </v-alert>
        </div>
        <div class="vc-row-center mt-5">
          <v-btn @click="openLoginDialog" color="primary"
            ><div class="vc-text-bold">fazer login</div></v-btn
          >
        </div>
      </div>
    </v-row>
    <v-dialog
      v-if="depedentModal"
      class="auth-dialog-index"
      v-model="depedentModal"
      max-width="450px"
    >
      <form-dependent
        :patient_id="patient_id"
        :company_id="company_id"
        :user_id="user.id"
        @getDependent="getDependent()"
        @closeDialog="closeDependentDialog"
      />
    </v-dialog>
  </v-form>
</template>

<script>
import ls from 'local-storage';
import CardScheduling from '../CardScheduling.vue';
import FormDependent from './FormDependent.vue';

export default {
  name: 'form-patient',
  props: {
    company_id: {
      type: String,
      default: '',
    },
    currentPatientId: {
      type: String,
      default: '',
    },
  },
  components: {
    CardScheduling,
    FormDependent,
  },
  data: () => ({
    patient_id: '',
    depedentModal: false,
    patients: [],
    catchNewDependent: false,
  }),
  watch: {
    currentPatientId(val) {
      if (val) {
        this.getDependent();
      }
    },
    user() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    init() {
      if (this.currentPatientId) {
        console.log(this.currentPatientId)
        this.getDependent();
      }
      if (!this.user) {
        this.$store.commit('SET_AUTH_MODAL', true);
      }
    },
    getDependent() {
      this.patient_id = ls.get('patientId');
      this.$store.commit('SET_PATIENT_ID', this.patient_id);
      this.patient_id = ls.get('peopleId');
      let body = {
        dependent_id: this.patient_id,
      };
      this.$store.dispatch('getPatientDependent', body).then((resp) => {
        this.patients = resp;
      });
    },
    setPatient(patient) {
      ls.set('currentPatient', patient);
      this.$store.commit('SET_CURRENT_PATIENT', patient);
      this.$emit('nextStep');
    },
    openLoginDialog() {
      this.$store.commit('SET_AUTH_MODAL', true);
    },
    closeDependentDialog() {
      this.depedentModal = false;
    },
  },
};
</script>
