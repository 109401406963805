import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#2E5F96',
        primaryLight: '#d3e8ff',
        vcBlue: '#0077ff',
        vcGrey: '#969696',
        vcLightGrey: '#f2f2f2',
        vcDarkGrey: '#636363',
        secondary: '#FF8E2A',
        secondaryLight: '#FFC99A',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
});
