<template>
  <div>
    <v-app-bar height="60px" class="vc-w-100" color="primary" dense dark>
      <v-app-bar-nav-icon
        v-if="$route.name === 'scheduling'"
        @click="hideOrShowMenu()"
      ></v-app-bar-nav-icon>
      <img
        v-if="!currentCompany"
        @click="toHome"
        src="@/assets/images/logo_venx_b.png"
        width="80px"
        class="vc-logo-button"
      />
      <img
        v-else
        @click="toHome"
        src="@/assets/images/logo_venx_b.png"
        width="80px"
        class="vc-logo-button"
      />

      <v-spacer></v-spacer>

      <v-btn @click="openLoginDialog" text v-if="!user" color="primaryLight">
        <v-icon size="23px" left dark color="primaryLight">
          mdi-account
        </v-icon>
        <div class="vc-text-bold">fazer login</div>
      </v-btn>

      <v-menu v-else offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text color="primaryLight" v-bind="attrs" v-on="on">
            <v-icon size="23px" left dark color="primaryLight">
              mdi-account
            </v-icon>
            <div class="vc-text-bold">{{ user.username }}</div>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-btn
              text
              @click="$router.push({ name: 'schedule-list' })"
              color="primary"
            >
              <v-icon left dark> mdi-calendar </v-icon>
              <div class="vc-text-bold">histórico</div>
            </v-btn>
          </v-list-item>
          <v-list-item v-if="user" @click="() => {}">
            <v-btn @click="confirmLogout = true" text color="error">
              <v-icon left dark color="error"> mdi-logout </v-icon>
              <div class="vc-text-bold">Sair</div>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu left bottom v-if="!user">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list class="v-list-toolbar">
          <v-list-item v-if="!user" @click="() => {}">
            <v-btn @click="openCadasterDialog" text color="primary">
              <div class="vc-text-bold">cadastrar</div>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <cadaster-dialog ref="cadasterDialog" />

    <auth-dialog v-if="authModal" />

    <forgot-password-dialog v-if="forgotpswdModal" />

    <v-dialog
      v-if="user && confirmLogout"
      v-model="confirmLogout"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">
          Deseja realmente sair {{ user.username }} ?
        </v-card-title>

        <!-- <v-card-text>
          Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.
        </v-card-text> -->

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red" text @click="confirmLogout = false">
            Canecelar
          </v-btn>

          <v-btn color="primary" @click="logout">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CadasterDialog from '@/components/auth/CadasterDialog.vue';
import AuthDialog from '@/components/auth/AuthDialog.vue';
import ForgotPasswordDialog from '@/components/auth/ForgotPasswordDialog.vue';

export default {
  name: 'header',
  components: {
    CadasterDialog,
    AuthDialog,
    ForgotPasswordDialog,
  },
  data: () => ({
    confirmLogout: false,
  }),
  computed: {
    hideMenu() {
      return this.$store.getters.hideMenu;
    },

    user() {
      return this.$store.getters.user;
    },
    authModal() {
      return this.$store.getters.authModal;
    },
    forgotpswdModal() {
      return this.$store.getters.forgotpswdModal;
    },
    currentCompany() {
      return this.$store.getters.currentCompany;
    },
  },
  methods: {
    hideOrShowMenu() {
      if (this.hideMenu == true) {
        this.$store.commit('HIDE_MENU', false);
      } else {
        this.$store.commit('HIDE_MENU', true);
      }
    },
    openLoginDialog() {
      this.$store.commit('SET_AUTH_MODAL', true);
    },
    openCadasterDialog() {
      this.$store.commit('SET_CADASTER_MODAL', true);
    },
    toHome() {
      this.$route.name != 'Home' ? this.$router.push({ name: 'Home' }) : '';
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'Home' });
      this.confirmLogout = false;
    },
  },
};
</script>
