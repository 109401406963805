<template>
  <v-card
    width="100%"
    max-width="550px"
    min-height="80px"
    elevation="2"
    class="vc-card-scheduling pa-0"
    @click="action"
  >
    <v-card-title>
      <div class="vc-flex-center">
        <div class="icon-box vc-flex-center pa-1">
          <v-icon color="white">{{ icon }}</v-icon>
        </div>
        <div class="white--text vc-text-bold vc-font-16 ml-2">
          {{ title }}
        </div>
      </div>
    </v-card-title>

    <v-radio-group v-model="radioCheck" class="mr-3">
      <v-radio color="white" value="selected"></v-radio>
    </v-radio-group>
  </v-card>
</template>

<script>
import ls from 'local-storage';

export default {
  name: 'card-scheduling',
  props: {
    id: { type: String, default: '' },
    title: { type: String, default: '' },
    icon: { type: String, default: 'mdi-file-document' },
    // type
    type: { type: String, default: '' },
  },
  data: () => ({
    radioCheck: '',
  }),
  mounted() {
    this.setValue();
  },
  methods: {
    action() {
      this.$emit('action');
    },
    setValue() {
      if (this.type === 'convenant' && ls.get('currentConvenant')) {
        this.id === ls.get('currentConvenant').id
          ? (this.radioCheck = 'selected')
          : '';
      } else if (this.type === 'specialty' && ls.get('currentSpecialty')) {
        this.id === ls.get('currentSpecialty').id
          ? (this.radioCheck = 'selected')
          : '';
      } else if (this.type === 'company' && ls.get('currentScheduleCompany')) {
        this.id === ls.get('currentScheduleCompany').id
          ? (this.radioCheck = 'selected')
          : '';
      } else if (this.type === 'patient' && ls.get('currentPatient')) {
        this.id === ls.get('currentPatient').id
          ? (this.radioCheck = 'selected')
          : '';
      }
    },
  },
};
</script>
