<template>
  <v-dialog
    class="auth-dialog-index"
    v-model="forgotpswdModal"
    persistent
    max-width="450px"
  >
    <v-card class="vcLightGrey pt-4 pl-4 pb-4 pr-4" v-if="step === 1">
      <v-row class="vc-auth-dialog vc-w-100 ma-0">
        <div @click="closeDialog" class="vc-auth-dialog-close">
          <v-icon color="vcDarkGrey"> mdi-close </v-icon>
        </div>
        <div @click="backDialog" class="vc-auth-dialog-back">
          <v-icon color="vcDarkGrey"> mdi-arrow-left </v-icon>
        </div>
        <v-col cols="12" class="text-center">
          <div class="vc-font-20 vc-text-bold">Recuperar senha</div>
        </v-col>
        <v-col cols="12" class="text-center ">
          <div class="vc-font-14">Por favor, informe o email cadastrado:</div>
        </v-col>
        <v-col cols="12" class="vc-auth-form-col pa-0 mt-4">
          <v-form
            class="vc-w-100 vc-auth-form"
            ref="loginForm"
            v-model="valid"
            lazy-validation
          >
            <v-row class="ma-0">
              <v-col cols="12" class="vc-auth-form-item-col pa-0">
                <v-text-field
                  v-model="modelUser.email"
                  dense
                  class="vc-w-100"
                  v-on:keyup.enter="login"
                  label="Email"
                  prepend-inner-icon="mdi-email"
                  :rules="[rules.required, rules.email]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="text-center pa-0 mt-5">
                <v-btn @click="sendEmail" keyup.enter depressed color="primary">
                  <div class="vc-text-bold">Enviar</div>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
    <!-- step 2 -->
    <v-card class="vcLightGrey pt-4 pl-4 pb-4 pr-4" v-if="step === 2">
      <v-row class="vc-auth-dialog vc-w-100 ma-0">
        <div @click="closeDialog" class="vc-auth-dialog-close">
          <v-icon color="vcDarkGrey"> mdi-close </v-icon>
        </div>
        <div @click="setStep(1)" class="vc-auth-dialog-back">
          <v-icon color="vcDarkGrey"> mdi-arrow-left </v-icon>
        </div>
        <v-col cols="12" class="text-center">
          <div class="vc-font-20 vc-text-bold">Recuperar senha</div>
        </v-col>
        <v-col cols="12" class="vc-auth-form-col pa-0 mt-4">
          <v-row class="ma-0">
            <v-card-text cols="12" class="text-center pa-1 my-1">
              Enviamos uma mensagem para <strong>{{ modelUser.email }}</strong
              >, caso esse email esteja cadastrado, você receberá um link para
              recuperar a sua conta em instantes.
            </v-card-text>
            <v-col cols="12" class="vc-row-center"
              ><div class="vc-font-12 mt-1 vc-text-darkGrey">
                Não recebeu nenhuma mensagem?
                <a class="vc-font-12" @click="sendEmail">reenviar email</a>
              </div>
            </v-col>
            <v-col cols="12" class="text-center pa-0 mt-4">
              <v-btn @click="closeDialog" keyup.enter depressed color="primary">
                <div class="vc-text-bold">Fechar</div>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'forgot-password-dialog',
  data() {
    return {
      step: 1,
      valid: false,
      rules: {
        required: (value) => !!value || 'Campo obrigatório!',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'E-mail inválido';
        },
      },
      modelUser: {
        email: '',
      },
    };
  },
  computed: {
    forgotpswdModal() {
      return this.$store.getters.forgotpswdModal;
    },
  },
  methods: {
    sendEmail() {
      if (this.emailIsValid(this.modelUser.email)) {
        this.$store
          .dispatch('recoveryPassword', {
            email: this.modelUser.email.trim(),
            url: `${process.env.VUE_APP_TEST}change-password`,
          })
          .then(() => {
            this.setStep(2);
          });
      }
    },
    emailIsValid(value) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) ? true : false;
    },
    setStep(step) {
      this.step = step;
    },
    closeDialog() {
      this.modelUser.email = '';
      this.$store.commit('SET_FORGOTPSWD_MODAL', false);
    },
    backDialog() {
      this.closeDialog();
      this.$store.commit('SET_AUTH_MODAL', true);
    },
  },
};
</script>
