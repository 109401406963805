<template>
  <body class="bg-image vc-row-center no-gutters" id="page-login">
    <div class="div-venx-logo-opacity">
      <img src="@/assets/images/venx-logo-opacity.svg" alt="" />
    </div>

    <div class="v-col">
      <div>
        <div>
          <div class="vc-row-center no-gutters">
            <div class="v-col hidden lg:block lg:w-1/2">
              <img
                width="225px"
                src="@/assets/images/forgot-password-svg.svg"
                alt="login"
                class="mx-auto mr-5"
              />
            </div>

            <div class="v-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div v-if="step === 1" class="p-6 login-tabs-container">
                <div class="flex flex-wrap justify-center mb-2">
                  <img
                    width="150"
                    src="@/assets/images/logo-vclinic_1.png"
                    alt=""
                  />
                </div>

                <v-alert
                  style="height: auto !important; "
                  type="primary"
                  
                  class="my-2 justify-center"
                >
                  Por favor, <strong>digite</strong> abaixo o
                  <strong>código</strong> enviado.
                </v-alert>

                <div>
                  <v-text-field
                    name="hash"
                    v-model="hash"
                    label="Digite o código"
                    clearable
                  ></v-text-field>

                  <v-alert
                    v-if="showError"
                    outlined
                    type="error"
                    
                    class="my-2 justify-center"
                  >
                    <div>
                      Por favor, <strong>preencha</strong> o campo acima
                    </div>
                  </v-alert>

                  <div class="flex flex-wrap justify-center my-5">
                    <v-btn
                      type="filled"
                      color="primary"
                      class="mt-2 float-right"
                      @click="validateHash"
                      ><div style="font-weight: 600;">Confirmar</div></v-btn
                    >
                  </div>
                </div>
              </div>

              <div v-if="step === 2" class="p-6 login-tabs-container">
                <div
                  style="width: 450px;"
                  class="flex flex-wrap justify-center mb-2"
                >
                  <img
                    width="150"
                    src="@/assets/images/logo-vclinic_1.png"
                    alt=""
                  />
                  <v-alert
                    style="height: auto;"                   
                    type="primary"
                    
                    class="my-2 justify-center"
                  >
                    <div class="text-center">
                      Por favor, <strong>digite</strong> sua nova
                      <strong>senha</strong>.
                    </div>
                  </v-alert>
                  <v-text-field
                    v-model="newPassword"
                    :type="'password'"
                    name="password"
                    label="Nova senha"
                  ></v-text-field>
                  <v-text-field
                    v-model="newPasswordTest"
                    :type="'password'"
                    name="password"
                    label="Confirmar senha"
                  ></v-text-field>

                  <v-alert
                    v-if="showError"
                    outlined
                    type="warning"
                    
                    class="my-2 justify-center"
                  >
                    <div class="text-center">
                      Por favor, <strong>preencha</strong> todos os campos
                      acima.
                    </div>
                  </v-alert>

                  <v-alert
                    v-if="showErrorEqual"
                    outlined
                    type="error"
                    
                    class="my-2 justify-center"
                  >
                    <div class="text-center">
                      As senha estão diferentes, <strong>por favor</strong>,
                      corrija.
                    </div>
                  </v-alert>

                  <v-btn
                    type="filled"
                    color="primary"
                    class="mt-2"
                    @click="saveNewPassword"
                    ><div style="font-weight: 600;">Confirmar </div></v-btn
                  >
                </div>
              </div>

              <div v-if="step === 3" class="p-6 login-tabs-container">
                <div class="flex flex-wrap justify-center mb-2">
                  <img
                    width="150"
                    src="@/assets/images/logo-vclinic_1.png"
                    alt=""
                  />
                  <v-alert
                   style="height: auto;"                   
                    type="primary"
                    
                    class="my-2 justify-center"
                  >
                    <div class="text-center">
                      Senha alterada com <strong>sucesso</strong>!
                    </div>
                  </v-alert>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
export default {
  name: 'change-password',
  data() {
    return {
      step: 1,
      show1: null,
      hash: '',
      newPassword: '',
      newPasswordTest: '',
      checkbox_remember_me: false,
      showError: false,
      showErrorEqual: false,
      load: false,
    };
  },

  methods: {
    setStep(val) {
      this.showError = false;
      this.step = val;
    },
    validateHash() {
      if (this.hash) {
        this.showError = false;
        this.$store
          .dispatch('verifyHashRecoveryPassword', { hash: this.hash.trim() })
          .then(() => {
            this.setStep(2);
          });
      } else {
        this.showError = true;
      }
    },
    saveNewPassword() {
      this.showError = false;
      if (this.newPassword && this.newPasswordTest) {
        this.showErrorEqual = false;
        if (this.newPassword === this.newPasswordTest) {
          this.$store
            .dispatch('changePassword', {
              new_password: this.newPassword,
            })
            .then(() => {
              this.setStep(3);
            });
        } else {
          this.showErrorEqual = true;
        }
      } else {
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="scss">
.div-venx-logo-opacity {
  width: 100%;
  position: absolute;
  bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-img {
  background-image: url('/src/assets/images/vuexy-login-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100vh;
}
body {
  background-color: #eff2f7;
}
#page-login {
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #4285f4;
    }
    .bg-github {
      background-color: #333;
    }
  }
}
</style>
