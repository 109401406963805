<template>
  <div class="vc-w-100 vc-flex-center">
    <v-card
      v-if="step === 1"
      class="vc-cadaster-card vc-fadeIn pa-6"
      min-width="300px"
    >
      <div @click="closeDialog" class="vc-cadaster-dialog-close">
        <v-icon color="vcDarkGrey"> mdi-close </v-icon>
      </div>
      <v-form
        class="vc-w-100 vc-cadaster-form"
        ref="cadasterForm"
        v-model="valid"
        lazy-validation
      >
        <v-col cols="12" class="pa-0 text-center vc-cadaster-form-header">
          <div class="vc-text-bold vc-font-20">Cadastre-se</div>
        </v-col>
        <v-row
          class="vc-cadaster-form-body pl-1 pr-1 pb-0 pt-0 ma-0 mt-4 vc-w-100"
        >
          <v-col cols="12" class="pa-0">
            <div class="ml-3">Nome</div>
            <v-text-field
              dense
              solo
              v-model="modelRegister.people.name"
              :rules="[rules.required]"
              class="vc-cadaster-input"
              placeholder="Digite aqui..."
              :disabled="infosPeople && (infosPeople.is_just_people || infosPeople.no_patient_relation)"
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="12" class="pa-0 mt-1">
            <div class="ml-3">Número do SUS</div>
            <v-text-field
              type="number"
              dense
              solo
              v-model="modelRegister.patient.num_sus"
              class="vc-cadaster-input"
              placeholder="Digite aqui..."
              :disabled="infosPeople && (infosPeople.no_patient_relation)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="mt-1 vc-cadaster-apply-responsivity-left"
          >
            <div class="ml-3">Telefone principal</div>
            <v-text-field
              dense
              solo
              v-model="modelRegister.people.phone1"
              :rules="[rules.required, rules.minPhone]"
              class="vc-cadaster-input"
              placeholder="Ex: (55)95555-5555"
              v-mask="'(##)#####-####'"
              :disabled="infosPeople && (infosPeople.is_just_people || infosPeople.no_patient_relation)"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="mt-1 vc-cadaster-apply-responsivity-right"
          >
            <div class="ml-3">Telefone opcional</div>
            <v-text-field
              dense
              solo
              v-mask="'(##)#####-####'"
              placeholder="Ex: (55)95555-5555"
              v-model="modelRegister.people.phone2"
              class="vc-cadaster-input"
              :disabled="infosPeople && (infosPeople.is_just_people || infosPeople.no_patient_relation)"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="pa-0 mt-1">
            <div class="ml-3">E-mail</div>
            <v-text-field
              dense
              solo
              v-model="modelRegister.people.email"
              :rules="[rules.required, rules.email]"
              class="vc-cadaster-input"
              placeholder="Digite aqui..."
              :disabled="infosPeople && (infosPeople.is_just_people || infosPeople.no_patient_relation)"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="pa-0 mt-1">
            <div class="ml-3">Sexo</div>
            <v-select
              v-model="modelRegister.people.gender"
              class="vc-cadaster-input"
              dense
              :rules="[rules.required]"
              :items="genders"
              item-value="slug"
              item-text="name"
              label="Selecione..."
              solo
              :disabled="infosPeople && (infosPeople.is_just_people || infosPeople.no_patient_relation)"
            ></v-select>
          </v-col>

          <v-col cols="12" class="pa-0 mt-1">
            <div class="ml-3">Data de Nascimento</div>
            <v-text-field
              dense
              solo
              v-model="modelRegister.people.birth_date"
              :rules="[rules.required, rules.minDate]"
              class="vc-cadaster-input"
              placeholder="DD/MM/YYYY"
              v-mask="'##/##/####'"
              :disabled="infosPeople && (infosPeople.is_just_people || infosPeople.no_patient_relation)"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="mt-1 vc-cadaster-apply-responsivity-left"
          >
            <div class="ml-3">CPF</div>
            <v-text-field
              dense
              solo
              v-model="modelRegister.people.cpf"
              :rules="[rules.required, rules.minCPF]"
              class="vc-cadaster-input"
              placeholder="Digite aqui..."
              v-mask="'###.###.###-##'"
              :disabled="infosPeople && (infosPeople.is_just_people || infosPeople.no_patient_relation)"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="mt-1 vc-cadaster-apply-responsivity-right"
          >
            <div class="ml-3">RG</div>
            <v-text-field
              dense
              solo
              type="number"
              v-model="modelRegister.people.rg"
              :rules="[rules.required]"
              class="vc-cadaster-input"
              placeholder="Digite aqui..."
              :disabled="infosPeople && (infosPeople.is_just_people || infosPeople.no_patient_relation)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="mt-1 vc-cadaster-apply-responsivity-left"
          >
            <div class="ml-3">CEP</div>
            <v-text-field
              dense
              solo
              v-model="modelRegister.address_data.zip_code"
              :rules="[rules.required, rules.minCEP]"
              class="vc-cadaster-input"
              placeholder="Digite aqui..."
              v-mask="'#####-###'"
              @keyup="getAddressByCep"
              :disabled="infosPeople && (infosPeople.no_patient_relation)"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="mt-1 vc-cadaster-apply-responsivity-right"
          >
            <div class="ml-3">Estado</div>
            <v-select
              v-model="modelRegister.address_data.state"
              class="vc-cadaster-input"
              dense
              :items="stateOptions"
              :reduce="(item) => item.sigla"
              :rules="[rules.required]"
              item-value="sigla"
              item-text="sigla"
              @input="getCities"
              label="Selecione..."
              solo
              :disabled="infosPeople && (infosPeople.no_patient_relation)"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="mt-1 vc-cadaster-apply-responsivity-left"
          >
            <div class="ml-3">Cidade</div>
            <v-select
              v-model="modelRegister.address_data.city"
              class="vc-cadaster-input"
              dense
              :rules="[rules.required]"
              :items="cityOptions"
              item-text="nome"
              item-value="nome"
              label="Selecione..."
              solo
              :disabled="infosPeople && (infosPeople.no_patient_relation)"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="mt-1 vc-cadaster-apply-responsivity-right"
          >
            <div class="ml-3">Bairro</div>
            <v-text-field
              dense
              solo
              type="text"
              v-model="modelRegister.address_data.neighborhood"
              :rules="[rules.required]"
              class="vc-cadaster-input"
              placeholder="Digite aqui..."
              :disabled="infosPeople && (infosPeople.no_patient_relation)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="8"
            class="mt-1 vc-cadaster-apply-responsivity-left"
          >
            <div class="ml-3">Rua</div>
            <v-text-field
              dense
              solo
              type="text"
              v-model="modelRegister.address_data.street"
              :rules="[rules.required]"
              class="vc-cadaster-input"
              placeholder="Digite aqui..."
              :disabled="infosPeople && (infosPeople.no_patient_relation)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="mt-1 vc-cadaster-apply-responsivity-right"
          >
            <div class="ml-3">Numero</div>
            <v-text-field
              dense
              solo
              type="number"
              v-model="modelRegister.address_data.number"
              :rules="[rules.required]"
              class="vc-cadaster-input"
              placeholder="Digite aqui..."
              :disabled="infosPeople && (infosPeople.no_patient_relation)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" class="pa-0 mt-1">
            <div class="ml-3">Complemento</div>
            <v-text-field
              dense
              solo
              type="text"
              v-model="modelRegister.address_data.complement"
              class="vc-cadaster-input"
              placeholder="Digite aqui..."
              :disabled="infosPeople && (infosPeople.no_patient_relation)"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-alert
          v-if="showAlert"
          dense
          border="left"
          color="primary"
          text
          type="info"
          class="mt-8 mb-0 text-center"
        >
          Por favor, preencha <strong>todos</strong> os campos acima para
          efetuar a ação!
        </v-alert>

        <v-col cols="12" class="vc-cadaster-form-footer pa-0 text-center mt-4">
          <v-btn @click="cadaster" :loading="loading" depressed color="primary">
            <div class="vc-text-bold">cadastrar</div>
          </v-btn>
        </v-col>
      </v-form>
    </v-card>

    <v-card
      v-else
      class="vc-cadaster-message vc-w-100 vc-fadeIn message"
      min-width="300px"
      max-width="600px"
    >
      <div class="vc-cadaster-message-header vc-flex-center pt-5 pl-5 pr-5">
        <img src="@/assets/images/logo-vclinic_1.png" height="30px" alt="" />
        <div class="vc-divider-grey mt-5"></div>
      </div>

      <div class="vc-cadaster-message-body pa-3 pt-4">
        <div class="vc-rounded primaryLight pa-3">
          <v-icon size="30px" color="primary"> mdi-email </v-icon>
        </div>

        <div class="vc-text-bold vc-text-uppercase vc-text-darkGrey mt-2">
          Verifique seu E-mail!
        </div>

        <div class="vc-font-16 vc-text-darkGrey mt-5">
          Sua senha temporada foi enviada via E-mail!
        </div>
      </div>

      <div class="vc-cadaster-message-footer vc-flex-center pa-3">
        <v-btn @click="closeDialog" color="primary">
          <v-icon left> mdi-close </v-icon>
          <div class="vc-text-bold">fechar</div>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { cepDao } from '@/service';
export default {
  name: 'form-cadaster',
  data() {
    return {
      loading: false,
      cityOptions: [],
      stateOptions: [],
      step: 1,
      valid: true,
      show1: false,
      showAlert: false,
      rules: {
        required: (value) => !!value || 'Campo obrigatório!',
        min: (v) => v.length >= 8 || 'Mínimo de 6 caracteres',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'E-mail inválido';
        },
        minPhone: (v) => v.length >= 14 || 'Mínimo de 11 caracteres!',
        minDate: (v) => v.length >= 10 || 'Data Incompleta!',
        minCPF: (v) => v.length >= 14 || 'Mínimo de 11 caracteres!',
        minCEP: (v) => v.length >= 9 || 'Mínimo de 8 caracteres!',
      },
      genders: [
        { name: 'Masculino', slug: 'M' },
        { name: 'Feminino', slug: 'F' },
      ],
      modelRegister: {
        people: {
          name: '',
          cpf: '',
          rg: '',
          birth_date: '',
          email: '',
          gender: '',
          phone1: '',
          phone2: '',
        },
        patient: {
          num_sus: '',
        },
        company_id: '',
        address_data: {
          zip_code: '',
          city: '',
          neighborhood: '',
          state: '',
          street: '',
          complement: '',
          number: '',
        },
        is_auth_user: true,
      },
      modelRegisterDefault: {
        people: {
          name: '',
          cpf: '',
          rg: '',
          birth_date: '',
          email: '',
          gender: '',
          phone1: '',
          phone2: '',
        },
        patient: {
          num_sus: '',
        },
        company_id: '',
        address_data: {
          zip_code: '',
          city: '',
          neighborhood: '',
          state: '',
          street: '',
          complement: '',
          number: '',
        },
      },
    };
  },
  computed: {
    currentCompany() {
      return this.$store.getters.currentCompany;
    },
    infosPeople() {
      return this.$store.getters.infosPeople;
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    infosPeople() {
      this.init();
    },
  },
  methods: {
    init() {
      this.step = 1;
      this.getStates();
      if (this.infosPeople != null) {
        this.modelRegister.people = {
          name: this.infosPeople.name,
          cpf: this.infosPeople.cpf,
          rg: this.infosPeople.rg,
          birth_date: this.infosPeople.birth_date,
          email: this.infosPeople.email,
          gender: this.infosPeople.gender,
          phone1: this.infosPeople.phone1,
          phone2: this.infosPeople.phone2,
        };
        if (this.infosPeople.no_patient_relation) {
          this.modelRegister.patient = {
            num_sus: this.infosPeople.patients.num_sus,
          };
          this.cityOptions.push({ nome: this.infosPeople.patients.address.city });
          this.modelRegister.address_data = {
            zip_code: this.infosPeople.patients.address.zip_code,
            city: this.infosPeople.patients.address.city,
            neighborhood: this.infosPeople.patients.address.neighborhood,
            state: this.infosPeople.patients.address.state,
            street: this.infosPeople.patients.address.street,
            complement: this.infosPeople.patients.address.complement,
            number: this.infosPeople.patients.address.number,
          };
        }
      }
    },
    getStates() {
      this.$store.dispatch('getAllStates').then((resp) => {
        this.stateOptions = resp;
      });
    },
    async getCities() {
      var selectedState = this.stateOptions.find(
        (state) => state.sigla === this.modelRegister.address_data.state
      );
      await this.$store.dispatch('getCityes', selectedState.id).then((resp) => {
        this.cityOptions = resp;
      });
    },
    getAddressByCep() {
      if (
        this.modelRegister.address_data.zip_code != null &&
        this.modelRegister.address_data.zip_code.length == 9
      ) {
        cepDao
          .get(this.modelRegister.address_data.zip_code)
          .then((resp) => {
            return resp.json();
          })
          .then((json) => {
            if (!json.erro) {
              this.modelRegister.address_data.state = json.uf;
              this.modelRegister.address_data.neighborhood = json.bairro;
              this.modelRegister.address_data.street = json.logradouro;
              this.cityOptions.push(json.localidade);
              this.modelRegister.address_data.city = json.localidade;
            }
          });
      }
    },
    cadaster() {
      this.showAlert = false;
      if (this.$refs.cadasterForm.validate()) {
        this.loading = true;
        this.modelRegister.company_id = this.currentCompany.id;
        this.formatCpf();
        this.formatDate();
        if(this.infosPeople){
          if (this.infosPeople.is_just_people) {
            delete this.modelRegister.people;
            this.modelRegister.people_id = this.infosPeople.id;
            this.modelRegister.is_auth_user = false;
          } else if (this.infosPeople.no_patient_relation) {
            delete this.modelRegister.people;
            this.modelRegister.is_auth_user = false;
            this.modelRegister.patient_id = this.infosPeople.patient_id;
          }
        }
        this.$store
          .dispatch('savePatientVCP', this.modelRegister)
          .then(() => {
            this.step = 2;
            this.modelRegister = this.modelRegisterDefault;
            this.$store.commit('SET_NOTIFICATION', {
              type: 'success',
              message: 'Cadastro de paciente Efetuado com Sucesso',
            });
            this.$store.commit(
              'SET_INFOS_PEOPLE',
              this.modelRegisterDefault.people
            );
            this.$store.commit('SET_CADASTER_MODAL', false);
            this.loading = false;
          })
          .catch((err) => {
            var messageError = '';
            switch (err.response.data.message) {
              case 'this email is already registered':
                messageError = 'Email já cadastrado.';
                break;
              case 'email already registered':
                messageError = 'Email já cadastrado';
                break;
              case 'this cpf is already registered':
                messageError = 'Cpf já cadastrado.';
                break;
              default:
                messageError = 'Não foi possivel cadastrar o paciente.';
            }
            this.$store.commit('SET_NOTIFICATION', {
              type: 'error',
              message: messageError,
            });
            this.loading = false;
          });

        // })
        // .catch((err) => {
        //   if (err.response.data.message == 'email already registered') {
        //     this.$store.commit('SET_NOTIFICATION', {
        //       type: 'danger',
        //       message: 'O email já esta cadastrado!',
        //     });
        //   } else {
        //     this.$store.commit('SET_NOTIFICATION', {
        //       type: 'danger',
        //       message: 'Não foi possivel cadastrar o usuário',
        //     });
        //   }
        // });
      } else {
        this.showAlert = true;
      }
    },
    closeDialog() {
      this.modelRegister = this.modelRegisterDefault;
      this.valid = true;
      this.$store.commit('SET_INFOS_PEOPLE', null);
      this.$store.commit('SET_CADASTER_MODAL', false);
    },
    formatCpf() {
      this.modelRegister.people.cpf = this.modelRegister.people.cpf.replaceAll(
        '.',
        ''
      );
      this.modelRegister.people.cpf = this.modelRegister.people.cpf.replace(
        '-',
        ''
      );
    },
    formatDate() {
      this.modelRegister.people.birth_date = this.modelRegister.people.birth_date.replaceAll(
        '/',
        '-'
      );
      this.modelRegister.people.birth_date = this.modelRegister.people.birth_date
        .split('-')
        .reverse()
        .join('-');
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #fff; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #a7a7a7; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: solid 3px #fff;
  // border: 3px solid orange; /* creates padding around scroll thumb */
}
</style>
