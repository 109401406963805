import { specialtyDao } from '@/service'

export default {
    state: {},
    mutations: {},
    actions: {
        getAllSpecialties() {
            const config = {
                params: {
                    perPage: 1000,
                }
            }
            return new Promise((resolve, reject) => {
                specialtyDao.getAll(config).getPage()
                    .then(resp => {

                        resolve(resp.data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        getAllSpecialit(_, payload) {
            return new Promise((resolve, reject) => {
                specialtyDao.getAllSpecialties(payload)
                    .then(resp => {
                        resolve(resp.data.data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
    },
    getters: {},
}