<template>
  <v-form ref="formClinic" lazy-validation class="vc-form-scheduling vc-w-100">
    <v-alert
      v-if="companies.length > 0"
      color="primary"
      dense
      text
      type="info"
      class="text-center"
    >
      Para <strong>selecionar</strong> a companhia desejada, por favor,
      selecione uma das opções abaixo.
    </v-alert>
    <v-row class="ma-0 vc-w-100">
      <v-col
        cols="12"
        md="6"
        v-for="(company, i) in companies"
        :key="`${i}${company.name}`"
        class="vc-flex-center pa-2 mt-3 mb-2"
      >
        <card-scheduling
          :id="company.id"
          :title="company.name"
          icon="mdi-domain"
          type="company"
          @action="setCompany(company)"
        />
      </v-col>
      <!-- <v-btn text @click="setCompany" color="primary">
        <div class="vc-text-bold">confirmar</div>
      </v-btn> -->
    </v-row>
  </v-form>
</template>

<script>
import ls from 'local-storage';
import CardScheduling from '../CardScheduling.vue';

export default {
  name: 'form-clinic',
  components: {
    CardScheduling,
  },
  data: () => ({
    companies: [
      { id: '1', name: 'Venx Tecnologia' },
      { id: '2', name: 'Elementos' },
      { id: '3', name: 'Clínica dos Olhos' },
      { id: '4', name: 'Hospital São Lucas' },
    ],
  }),
  methods: {
    setCompany(company) {
      ls.set('currentScheduleCompany', company);
      this.$store.commit('SET_CURRENT_SCHEDULE_COMPANY', company);
      this.$emit('nextStep');
    },
  },
};
</script>
