import Paginator from '../_paginator'
import http from '../api'

let getAll = (config) => {
    return new Paginator({
        url: 'doctors',
        config: config
    });
}

let getAllDoctors = (data) => {
    return http.get(`doctor-generic/${data}`)
}

let getDoctor = (doctor_id) => {
    return http.get(`doctors/${doctor_id}`)
}

let updateDoctor = (doctor_id, data) => {
    return http.put(`doctors/${doctor_id}`, data)
}

let removeDoctor = (doctor_id) => {
    return http.delete(`doctors/${doctor_id}`)
}

let doctorVerify = (data) => {
    return http.post('doctor-verify', data);
}

let saveDoctor = (data) => {
    return http.post('doctors', data);
}

let getAllDocuments = () => {
    return http.get('typedocument')
}

export default {
    getAll,
    getAllDoctors,
    getDoctor,
    doctorVerify,
    saveDoctor,
    getAllDocuments,
    removeDoctor,
    updateDoctor,
}