import axios from 'axios';

let getAllStates = () => {
 return axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome');
};
let getCityes = (data) => {
  return axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${data}/municipios`);
 };

export default {
  getAllStates,
  getCityes,
};