<template>
  <v-form
    ref="formConfirmation"
    lazy-validation
    class="vc-form-scheduling vc-w-100"
  >
    <v-row class="ma-0 w-100">
      <v-col v-if="step === 1" cols="12" class="vc-flex-center pa-0">
        <div class="vc-font-16 vc-text-primary vc-text-bold">
          Por favor, verifique seu agendamento!
        </div>
      </v-col>

      <v-col v-if="step === 1" cols="12" class="vc-flex-center pa-0 mt-2">
        <card-confirmation @setStep="setStep" @setQrCode="setQrCode" />
      </v-col>

      <v-col v-else text border="left" cols="12" class="vc-column-center pa-0">
        <v-btn @click="confirmFeedback = true" color="primary"
          ><div class="vc-text-bold">
            comprovante
          </div></v-btn
        >
      </v-col>

      <v-dialog
        v-model="confirmFeedback"
        v-if="step === 2 && confirmFeedback"
        max-width="850px"
      >
        <card-confirm-feedback
          v-if="step === 2"
          :company="currentScheduleCompany"
          :schedule="currentSchedule"
          :convenant="currentConvenant"
          :specialty="currentSpecialty"
          :doctor="currentDoctor"
          :patient="currentPatient"
          :qr_code="qr_code"
        />
      </v-dialog>
    </v-row>
  </v-form>
</template>

<script>
import CardConfirmation from '../CardConfirmation.vue';
import CardConfirmFeedback from '../CardConfirmFeedback.vue';

export default {
  name: 'form-confirmation',
  components: {
    CardConfirmation,
    CardConfirmFeedback,
  },
  data: () => ({
    step: 1,
    qr_code: null,
    confirmFeedback: false,
    modelScheduling: {
      patient_id: '',
      name_patient: '',
      schedule_id: '',
      time: '',
      observation: '',
      return_id: '',
      status: '',
    },
  }),
  computed: {
    currentScheduleCompany() {
      return this.$store.getters.currentScheduleCompany;
    },
    currentSchedule() {
      return this.$store.getters.currentSchedule;
    },
    currentConvenant() {
      return this.$store.getters.currentConvenant;
    },
    currentSpecialty() {
      return this.$store.getters.currentSpecialty;
    },
    currentDoctor() {
      return this.$store.getters.currentDoctor;
    },
    currentPatient() {
      return this.$store.getters.currentPatient;
    },
  },
  methods: {
    setStep(val) {
      this.step = val;
    },
    setQrCode(val) {
      this.qr_code = val;
    },
  },
};
</script>
