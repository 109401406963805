<template>
  <div>
    <div class="invoice p-3 mb-3 padding-style-one" id="print">
      <!-- <div class="div-dont-want-to-display"> -->
      <!-- AND MANY MORE DIV'S -->
      <v-card class="pa-2 padding-style">
        <div class="vc-card-confirm vc-column-center">
          <div class="primaryLight vc-w-100 vc-row-center pa-3 padding-style">
            <div class="padding-style-one">
              <img
                width="130px"
                src="@/assets/images/logo-vclinic_1.png"
                alt=""
              />
            </div>

            <h2 class="ml-10 padding-style-one">{{ currentCompany.name }}</h2>
          </div>

          <div class="vc-w-100 pa-3 padding-style">
            <v-row class="ma-0 vc-w-100">
              <v-col
                cols="7"
                class="vc-card-confirm-info vc-row-center pa-1 pr-2 padding-style"
              >
                <div
                  class="infos vc-column-center vc-w-100 vc-h-100 pt-2 pb-2 pl-4 pr-4"
                >
                  <div class="vc-font-18">{{ specialty.name }}</div>
                  <div class="vc-divider-grey mt-2 mb-3"></div>
                  <div class="infos-item vc-w-100">
                    <div class="primaryLight pa-2 padding-style-one">
                      <v-icon color="primary" size="32px">
                        mdi-calendar-blank
                      </v-icon>
                    </div>
                    <v-row class="ma-0 ml-2">
                      <v-col cols="12" class="pa-0 vc-font-16 vc-text-bold"
                        >Data</v-col
                      >
                      <v-col cols="12" class="pa-0 vc-font-16">{{
                        schedule.scheduling
                      }} as {{schedule.time}}</v-col>
                    </v-row>
                  </div>

                  <div class="infos-item vc-w-100 mt-4">
                    <div class="primaryLight pa-2 padding-style-one">
                      <v-icon color="primary" size="32px">
                        mdi-briefcase-account-outline
                      </v-icon>
                    </div>
                    <v-row class="ma-0 ml-2">
                      <v-col cols="12" class="pa-0 vc-font-16 vc-text-bold"
                        >Profissional da Saúde</v-col
                      >
                      <v-col cols="12" class="pa-0 vc-font-16">{{
                        doctor.name_doctor
                      }}</v-col>
                    </v-row>
                  </div>

                  <div class="infos-item vc-w-100 mt-4">
                    <div class="primaryLight pa-2 padding-style-one">
                      <v-icon color="primary" size="32px">
                        mdi-account-outline
                      </v-icon>
                    </div>
                    <v-row class="ma-0 ml-2">
                      <v-col cols="12" class="pa-0 vc-font-16 vc-text-bold"
                        >Paciente</v-col
                      >
                      <v-col cols="12" class="pa-0 vc-font-16">{{
                        patient.username
                      }}</v-col>
                    </v-row>
                  </div>

                  <div class="infos-item vc-w-100 mt-4">
                    <div class="primaryLight pa-2 padding-style-one">
                      <v-icon color="primary" size="32px">
                        mdi-calendar-blank
                      </v-icon>
                    </div>
                    <v-row class="ma-0 ml-2">
                      <v-col cols="12" class="pa-0 vc-font-16 vc-text-bold text-uppercase"
                        >{{currentCompany.address.street}}, {{currentCompany.address.neighborhood}}</v-col
                      >
                     
                      <v-col cols="12" class="pa-0 vc-font-16 text-uppercase"
                        >{{currentCompany.address.city}}, {{currentCompany.address.state}} - {{currentCompany.address.zip_code}}</v-col
                      >
                      
                   
                    </v-row>
                  </div>
                </div>
              </v-col>
              <v-col cols="5" class="vc-card-confirm-info pa-1 pl-2">
                <div
                  id="qr-code-content"
                  class="infos vc-w-100 vc-h-100 pt-2 pb-2 pl-4 pr-4"
                >
                  <div class="vc-font-18">QR Code</div>
                  <div class="vc-divider-grey mt-2 mb-3"></div>
                  <div class="vc-h-100 vc-flex-center">
                    <qrcode-vue
                      :value="qr_code"
                      size="150"
                      level="H"
                      renderAs="svg"
                    ></qrcode-vue>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>
      <!-- </div> -->
    </div>

    <!--BUTTON TO DOWNLOAD PDF-->
    <div class="vc-w-100 text-end">
      <v-btn @click.prevent="createPDF" depressed color="primary">
        Baixar PDF
      </v-btn>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import html2pdf from 'html2pdf.js';
export default {
  name: 'card-confirm-feedback',
  components: {
    QrcodeVue,
  },
  props: {
    company: { type: Object, default: () => {} },
    schedule: { type: Object, default: () => {} },
    convenant: { type: Object, default: () => {} },
    specialty: { type: Object, default: () => {} },
    doctor: { type: Object, default: () => {} },
    patient: { type: Object, default: () => {} },
    qr_code: { type: String, default: '' },
  },
  data: () => ({}),
  computed: {
    currentCompany() {
      return this.$store.getters.currentCompany;
    },
  },

  methods: {
    createPDF() {
      var element = document.getElementById('print');

      var opt = {
        margin: 10,
        filename: 'comprovante.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: 'mm',
          format: [280, 450],
          orientation: 'portrait',
        },
      };

      html2pdf()
        .set(opt)
        .from(element)
        .save();
    },
  },
};
</script>
<style>
.padding-style-one {
  padding: 10px;
}
.padding-style {
  padding: 20px;
}
.primaryLight {
  background-color: #d3e8ff;
}
</style>
