<template>
  <v-dialog
    class="auth-dialog-index"
    v-model="cadasterModal"
    persistent
    max-width="450px"
  >
    <form-cadaster @closeDialog="closeDialog" />
  </v-dialog>
</template>

<script>
import FormCadaster from './forms/form-cadaster.vue';

export default {
  name: 'cadaster-dialog',
  components: {
    FormCadaster,
  },
  data: () => ({
  }),
  computed: {
    cadasterModal() {
      return this.$store.getters.cadasterModal;
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit('SET_CADASTER_MODAL', false);
    },
  },
};
</script>
