<template>
  <v-card
    width="100%"
    max-width="700px"
    min-width="240px"
    elevation="2"
    class="pa-0 bg-color"
  >
    <v-card-title class="vc-between-center ">
      <div class="vc-flex-center">
        <div class="icon-box vc-flex-center pa-1">
          <v-icon size="30px" color="primary">mdi-ballot</v-icon>
        </div>
        <div class="vc-text-primary vc-text-bold vc-font-18 ml-2">
          Agendamento
        </div>
      </div>
      <div
        v-if="schedule.status === 'CONFIRMED'"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon size="28" color="success" v-bind="attrs" v-on="on">
              mdi-account-check
            </v-icon>
          </template>
          <span>Consulta confirmada!</span>
        </v-tooltip>
      </div>
      <div
        v-else-if="schedule.status === 'SCHEDULED'"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon size="28" color="warning" v-bind="attrs" v-on="on">
              mdi-clock-time-eight-outline
            </v-icon>
          </template>
          <span>Consulta Agendada!</span>
        </v-tooltip>
      </div>
      <div v-else-if="schedule.status === 'ONHOLD' || schedule.status === 'ONPAUSE'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon size="28" color="gray" v-bind="attrs" v-on="on">
              mdi-clock-time-eight-outline
            </v-icon>
          </template>
          <span>Consulta em Espera!</span>
        </v-tooltip>
      </div>
      <div v-else-if="schedule.status === 'ONCONSULTATION'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon size="28" color="success" v-bind="attrs" v-on="on">
              mdi-clipboard-check-outline
            </v-icon>
          </template>
          <span>Consulta em andamento!</span>
        </v-tooltip>
      </div>
      <div v-else-if="schedule.status === 'ATTENDED'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon size="28" color="success" v-bind="attrs" v-on="on">
              mdi-check-outline
            </v-icon>
          </template>
          <span>Consulta finalizada!</span>
        </v-tooltip>
      </div>
      <div v-else-if="schedule.status === 'CALLEDOFF'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon size="28" color="error" v-bind="attrs" v-on="on">
              mdi-close-outline
            </v-icon>
          </template>
          <span>Consulta Cancelada!</span>
        </v-tooltip>
      </div>
      <div v-else-if="schedule.status === 'ABSENT'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon size="28" color="error" v-bind="attrs" v-on="on">
              mdi-close-outline
            </v-icon>
          </template>
          <span>Paciente Ausente!</span>
        </v-tooltip>
      </div>
    </v-card-title>

    <div class="pl-2 pr-2">
      <div class="vc-divider-grey"></div>
    </div>

    <v-card-title class="pt-0 pb-0 mt-3">
      <div class="vc-flex-center">
        <div class="icon-box vc-flex-center pa-1">
          <v-icon>mdi-briefcase-account</v-icon>
        </div>
        <div class="vc-font-16 ml-2">
          <strong
            >Profissional da saúde:
            {{ schedule.schedule.doctor.people.name }}</strong
          >
        </div>
      </div>
    </v-card-title>

    <v-card-title class="pt-0 pb-0 mt-3">
      <div class="vc-flex-center">
        <div class="icon-box vc-flex-center pa-1">
          <v-icon>mdi-account-hard-hat</v-icon>
        </div>
        <div class="vc-font-16 ml-2">
          <strong>Especialidade: </strong
          >{{ schedule.schedule.specialist[0].name }}
        </div>
      </div>
    </v-card-title>

    <v-card-title class="pt-0 pb-0 mt-3">
      <div class="vc-flex-center">
        <div class="icon-box vc-flex-center pa-1">
          <v-icon>mdi-card-account-details-star</v-icon>
        </div>
        <div class="vc-font-16 ml-2">
          <strong>Convênio: </strong>{{ schedule.health_insurance.name }}
          <strong>Plano: </strong>{{ schedule.health_plan.name }}
        </div>
      </div>
    </v-card-title>

    <v-card-title class="pt-0 pb-0 mt-3">
      <div class="vc-flex-center">
        <div class="icon-box vc-flex-center pa-1">
          <v-icon>mdi-calendar-month</v-icon>
        </div>
        <div class="vc-font-16 ml-2">
          <strong>Data e hora:</strong> {{ feedbackData.scheduling }} as
          {{ schedule.time }}
        </div>
      </div>
    </v-card-title>

    <v-card-title class="pt-0 pb-0 mt-3 mb-3">
      <div class="vc-flex-center">
        <div class="icon-box vc-flex-center pa-1">
          <v-icon>mdi-account</v-icon>
        </div>
        <div class="vc-font-16 ml-2">
          <strong>Paciente:</strong> {{ schedule.patient.people.name }}
        </div>
      </div>
    </v-card-title>

    <div class="pl-2 pr-2">
      <div class="vc-divider-grey"></div>
    </div>

    <v-card-actions class="between-row">
      <div>
        <v-btn
          :disabled="
            schedule.status === 'CALLEDOFF' ||
              schedule.status === 'ONCONSULTATION'
          "
          @click="seeQrCode = true"
          color="primary"
          class="mr-3"
        >
          <div class="vc-text-bold">Imprimir conprovante</div>
        </v-btn>
      </div>

      <div class="pa-2 text-end">
        <v-btn
          :disabled="schedule.status != 'SCHEDULED'"
          @click="openRemoveDialog"
          text
          color="error"
          class="mr-3"
        >
          <div class="vc-text-bold">cancelar consulta</div>
        </v-btn>

        <v-btn
          :disabled="schedule.status != 'SCHEDULED'"
          @click="openUpdateDialog"
          color="primary"
        >
          <div class="vc-text-bold">atualizar</div>
        </v-btn>
      </div>
    </v-card-actions>

    <v-dialog v-model="seeQrCode" max-width="900px">
      <v-card class="vc-column-center pa-2">
        <div class="vc-text-bold vc-font-18 mb-3">Comprovante</div>
        <card-confirm-feedback
          :schedule="feedbackData"
          :specialty="feedbackData"
          :doctor="feedbackData"
          :patient="feedbackData"
          :qr_code="schedule.qr_code"
        />
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import CardConfirmFeedback from './CardConfirmFeedback.vue';
import moment from 'moment';

export default {
  name: 'card-patient-schedule',
  components: {
    CardConfirmFeedback,
  },
  props: {
    schedule: { type: Object, default: () => {} },
  },
  data: () => ({
    feedbackData: {},
    seeQrCode: false,
    doctorName: null,
  }),
  computed: {
    currentScheduleCompany() {
      return this.$store.getters.currentScheduleCompany;
    },
    currentSchedule() {
      return this.$store.getters.currentSchedule;
    },
    currentConvenant() {
      return this.$store.getters.currentConvenant;
    },
    currentSpecialty() {
      return this.$store.getters.currentSpecialty;
    },
    currentDoctor() {
      return this.$store.getters.currentDoctor;
    },
    currentPatient() {
      return this.$store.getters.currentPatient;
    },
    currentCompany() {
      return this.$store.getters.currentCompany;
    },
  },
  mounted() {
    this.feedbackData = {
      username: this.schedule.patient.people.name,
      name_doctor: this.schedule.schedule.doctor.people.name,
      scheduling: moment(this.schedule.schedule.date).format('DD/MM/YYYY'),
      time: this.schedule.time,
      name: this.schedule.schedule.specialist[0].name,
    };
  },
  methods: {
    openRemoveDialog() {
      this.$emit('openRemoveDialog', this.schedule);
    },
    openUpdateDialog() {
      this.$emit('openUpdateDialog', this.schedule);
    },
  },
};
</script>

<style lang="scss" scoped>
.between-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bg-color {
  /* border: solid 1px #2a4463; */
  background-color: #ecececd7;
  box-shadow: 0px 0px 15px 2px #535252;
}
</style>
