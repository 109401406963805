import { ibgeDao } from '@/service';

export default {
  state: {},
  mutations: {},
  actions: {
    getAllStates() {
      return new Promise((resolve, reject) => {
        ibgeDao
          .getAllStates()
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getCityes(_, payload) {
      return new Promise((resolve, reject) => {
        ibgeDao
          .getCityes(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {},
};