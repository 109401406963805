import Vue from 'vue'
import VueRouter from 'vue-router';
import Main from '../views/Main.vue';
import Home from '../views/Home.vue';
import Scheduling from '../views/pages/Scheduling.vue';
import ScheduleList from '../views/pages/ScheduleList.vue';
import ChangePassword from '../views/auth/ChangePassword.vue';
import Error404 from '../views/pages/Error404';
  // import MaintenceScreen from '../views/pages/MaintenceScreen.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    name: 'Main',
    beforeEnter: existId,
    component: Main,
    children: [
      {
        path: '/:id/home',
        name: 'Home',
        component: Home,
      },
      {
        path: '/:id/scheduling',
        name: 'scheduling',
        component: Scheduling,
      },
      {
        path: '/:id/schedule-list',
        name: 'schedule-list',
        component: ScheduleList,
      },
    ],
  },

  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePassword,
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: Error404,
  },
  {
    path: '*',
    redirect: '/error-404',
  },
];

function existId(to, from, next) {
  if (to.params.id) {
    console.log('entrei')
    next();
  } else {
    next({ name: 'error-404' });
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
