<template>
  <v-form
    ref="formSpecialty"
    lazy-validation
    class="vc-form-scheduling vc-w-100"
  >
    <v-alert
      v-if="specialties.length > 0"
      color="primary"
      dense
      text
      type="info"
      class="text-center"
    >
      Para <strong>selecionar</strong> a especialidade desejada, por favor,
      selecione a opção <strong>'Selecionar'</strong> abaixo.
    </v-alert>
    <v-row class="ma-0 vc-w-100">
      <v-col
        cols="12"
        md="6"
        v-for="(specialty, i) in specialties"
        :key="`${i}${specialty.name}`"
        class="vc-flex-center pa-2 mt-3 mb-2"
      >
        <card-scheduling
          :id="specialty.id"
          :title="specialty.name"
          icon="mdi-account"
          type="specialty"
          @action="setSpecialty(specialty)"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ls from 'local-storage';
import CardScheduling from '../CardScheduling.vue';

export default {
  name: 'form-specialty',
  props: {
    company_id: { type: String, default: '' },
  },
  components: {
    CardScheduling,
  },
  data: () => ({
    specialties: [],
  }),
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let body = this.formatQueryParams({company_id:this.company_id});        
      this.$store.dispatch('getAllSpecialit',body).then((resp) => {  
        this.specialties = resp;
      });
    },
    setSpecialty(specialty) {
      ls.set('currentSpecialty', specialty);
      this.$store.commit('SET_CURRENT_SPECIALTY', specialty);
      this.$emit('nextStep');
    },
  },
};
</script>
