import { patientDao } from '@/service';

export default {
  state: {
    currentPatientId: null,
  },
  mutations: {
    SET_PATIENT_ID(state, payload) {
      state.currentPatientId = payload;
    },
  },
  actions: {
    cadasterUser(_, payload) {
      return new Promise((resolve, reject) => {
        patientDao
          .cadasterUser(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPatient(_, payload) {
      return new Promise((resolve, reject) => {
        patientDao
          .getPatient(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    savePatient(_, payload) {
      return new Promise((resolve, reject) => {
        patientDao
          .savePatient(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    savePatientVCP(_, payload) {
      return new Promise((resolve, reject) => {
        patientDao
          .savePatientVCP(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPatientDependent( _,payload) {
      return new Promise((resolve, reject) => {
        patientDao
          .getPatientDependent(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  modules: {
    currentPatientId: (state) => state.currentPatientId,
  },
  getters: {
    currentPatientId: state => state.currentPatientId,
  },
};
