<template>
  <v-form
    ref="formConvenant"
    lazy-validation
    class="vc-form-scheduling vc-w-100"
  >
    <v-alert
      v-if="convenants.length > 0"
      color="primary"
      dense
      text
      type="info"
      class="text-center"
    >
      Para <strong>selecionar</strong> o convênio desejado, por favor, selecione
      a opção <strong>'Selecionar'</strong> abaixo.
    </v-alert>
    <v-row class="ma-0 vc-w-100">
      <v-col cols="12" md="12" class="vc-flex-center pa-2 mt-3 mb-2">
        <v-expansion-panels>
          <v-expansion-panel 
            v-for="(convenant, i) in convenants"
            :key="`${i}${convenant.slug}`"
          >
            <v-expansion-panel-header  v-if="convenants[i].health_insurance.health_plan.length > 0">
              {{ convenant.health_insurance.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content             
            >
              <div
                v-for="(healthPlan, i) in convenants[i].health_insurance.health_plan"
                :key="`${i}${healthPlan.id}`"
              >
                <card-scheduling
                  :id="healthPlan.id"
                  :title="healthPlan.name"
                  icon="mdi-card-account-details-star"
                  type="convenant"
                  class="mb-2"
                  @action="setConvenant(healthPlan)"
                />
              </div>
            </v-expansion-panel-content>       
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ls from 'local-storage';
import CardScheduling from '../CardScheduling.vue';

export default {
  name: 'form-convenant',
  props: {
    company_id: { type: String, default: '' },
  },
  components: {
    CardScheduling,
  },
  data: () => ({
    convenants: [],
    healthPlans: [],
    consult: {
      company_id: '',
      is_active: true,
    },
    rules: {
      required: (value) => !!value || 'Campo obrigatório!',
    },
  }),
  mounted() {
    
    this.init();
  },
  methods: {
    init() {
      this.consult.company_id = this.company_id;
      let body = this.formatQueryParams(this.consult);
      this.$store.dispatch('getAllConvenants', body).then((resp) => {
        console.log(resp)
        this.convenants = resp;
      });
    },
    setConvenant(convenant) {
      ls.set('currentConvenant', convenant);
      this.$store.commit('SET_CURRENT_CONVENANT', convenant);
      this.$emit('nextStep');
    },
  },
};
</script>
