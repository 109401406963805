import { authDao } from '@/service';
import ls from 'local-storage';

export default {
  state: {
    user: null,
    authModal: false,
    currentCompany: null,
    cadasterModal: false,
    forgotpswdModal: false,
    notification: null,
    infosPeople: null,
    hideMenu: false,
    token: '',
  },
  mutations: {
    HIDE_MENU(state, payload) {
      state.hideMenu = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_AUTH_MODAL(state, payload) {
      state.authModal = payload;
    },
    SET_CADASTER_MODAL(state, payload) {
      state.cadasterModal = payload;
    },
    SET_INFOS_PEOPLE(state, payload) {
      console.log('payload', payload);
      state.infosPeople = payload;
    },
    SET_FORGOTPSWD_MODAL(state, payload) {
      state.forgotpswdModal = payload;
    },
    SET_CURRENT_COMPANY(state, payload) {
      state.currentCompany = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    UNSET_USER(state) {
      state.user = null;
    },
    SET_NOTIFICATION(state, payload) {
      state.notification = payload;
    },
  },
  actions: {
    initAuth({ commit }) {
      return new Promise((resolve) => {
        if (!ls.get('t')) {
          commit('UNSET_USER');
          resolve();
          return;
        } else {
          authDao
            .currentUser()
            .then((resp) => {
              commit('SET_USER', resp.data);
              resolve(resp);
            })
            .catch(() => {
              commit('UNSET_USER');
              resolve();
              return;
            });
        }
      });
    },
    getCompany(_, payload) {
      return new Promise((resolve, reject) => {
        authDao
          .getCompany(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cadaster(_, payload) {
      return new Promise((resolve, reject) => {
        authDao
          .cadaster(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        authDao
          .login(payload)
          .then(async (resp) => {
            await ls.set('t', resp.data.token);
            authDao
              .currentUser()
              .then((resp) => {
                commit('SET_USER', resp.data);
                resolve();
              })
              .catch(() => {
                commit('UNSET_USER');
                resolve();
                return;
              });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    changePassword({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        authDao
          .changePassword(payload, state.token)
          .then((resp) => {
            commit('SET_NOTIFICATION', {
              message: 'Senha modificada com sucesso',
              type: 'success',
            });
            resolve(resp);
          })
          .catch((erro) => {
            commit('SET_NOTIFICATION', {
              message: 'Erro ao mudar de senha',
              type: 'success',
            });
            reject(erro);
          });
      });
    },
    verifyHashRecoveryPassword({ commit }, payload) {
      return new Promise((resolve, reject) => {
        authDao
          .verifyHashRecoveryPassword(payload)
          .then((resp) => {
            commit('SET_TOKEN', resp.data.token.token);
            commit('SET_NOTIFICATION', {
              message: 'Código validado com sucesso',
              type: 'success',
            });
            resolve(resp);
          })
          .catch((erro) => {
            commit('SET_NOTIFICATION', {
              message: 'Código não validado',
              type: 'danger',
            });
            reject(erro);
          });
      });
    },
    socialLogin(_, payload) {
      return new Promise((resolve, reject) => {
        authDao
          .socialLogin(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    logout({ commit }) {
      ls.clear();
      commit('UNSET_USER');
      commit('SET_SCHEDULE_DATA_NULL');
    },
    recoveryPassword(_, payload) {
      return new Promise((resolve, reject) => {
        authDao
          .recoveryPassword(payload)
          .then((resp) => {
            resolve(resp);
          })
          .catch((erro) => {
            reject(erro);
          });
      });
    },
  },
  getters: {
    authModal: (state) => state.authModal,
    cadasterModal: (state) => state.cadasterModal,
    forgotpswdModal: (state) => state.forgotpswdModal,
    user: (state) => state.user,
    currentCompany: (state) => state.currentCompany,
    notification: (state) => state.notification,
    infosPeople: (state) => state.infosPeople,
    hideMenu: (state) => state.hideMenu,
  },
};
