<template>
  <div class="vc-w-100 vc-flex-center">
    <v-card
      v-if="step === 1"
      class="vc-cadaster-card vc-fadeIn pa-6"
      min-width="300px"
    >
      <div @click="closeDialog" class="vc-cadaster-dialog-close">
        <v-icon color="vcDarkGrey"> mdi-close </v-icon>
      </div>
      <v-form
        class="vc-w-100 vc-cadaster-form"
        ref="dependentForm"
        v-model="valid"
        lazy-validation
      >
        <v-col cols="12" class="pa-0 text-center vc-cadaster-form-header">
          <div class="vc-text-bold vc-font-20">Novo dependente</div>
        </v-col>
        <v-row
          class="vc-cadaster-form-body pl-1 pr-1 pb-0 pt-0 ma-0 mt-4 vc-w-100"
        >
          <v-col cols="12" class="pa-0">
            <div class="ml-3">Nome</div>
            <v-text-field
              dense
              solo
              v-model="modelDependent.people.name"
              :rules="[rules.required]"
              class="vc-cadaster-input"
              placeholder="Digite aqui..."
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="12" class="pa-0 mt-4">
            <div class="ml-3">Número do SUS</div>
            <v-text-field
              type="number"
              dense
              solo
              v-model="modelDependent.patient.num_sus"
              :rules="[rules.required]"
              class="vc-cadaster-input"
              placeholder="Digite aqui..."
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="mt-4 vc-cadaster-apply-responsivity-left"
          >
            <div class="ml-3">Telefone principal</div>
            <v-text-field
              dense
              solo
              v-model="modelDependent.people.phone1"
              :rules="[rules.required]"
              class="vc-cadaster-input"
              placeholder="Ex: (55)5555-5555"
              v-mask="'(##)####-####'"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="mt-4 vc-cadaster-apply-responsivity-right"
          >
            <div class="ml-3">Telefone opcional</div>
            <v-text-field
              dense
              solo
              v-mask="'(##)####-####'"
              placeholder="Ex: (55)5555-5555"
              v-model="modelDependent.people.phone2"
              class="vc-cadaster-input"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0 mt-5">
            <div class="ml-3">E-mail</div>
            <v-text-field
              dense
              solo
              v-model="modelDependent.people.email"
              :rules="[rules.required, rules.email]"
              class="vc-cadaster-input"
              placeholder="Digite aqui..."
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="pa-0 mt-5">
            <div class="ml-3">Sexo</div>
            <v-select
              v-model="modelDependent.people.gender"
              class="vc-cadaster-input"
              dense
              :rules="[rules.required]"
              :items="genders"
              item-value="slug"
              item-text="name"
              label="Selecione..."
              solo
            ></v-select>
          </v-col>
          <v-col cols="12" class="pa-0 mt-5">
            <div class="ml-3">Data de Nascimento</div>
            <v-text-field
              dense
              solo
              v-model="modelDependent.people.birth_date"
              :rules="[rules.required]"
              class="vc-cadaster-input"
              placeholder="DD/MM/YYYY"
              v-mask="'##/##/####'"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="mt-5 vc-cadaster-apply-responsivity-left"
          >
            <div class="ml-3">CPF</div>
            <v-text-field
              dense
              solo
              v-model="modelDependent.people.cpf"
              :rules="[rules.required]"
              class="vc-cadaster-input"
              placeholder="Digite aqui..."
              v-mask="'###.###.###-##'"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="mt-5 vc-cadaster-apply-responsivity-right"
          >
            <div class="ml-3">RG</div>
            <v-text-field
              dense
              solo
              v-model="modelDependent.people.rg"
              :rules="[rules.required]"
              class="vc-cadaster-input"
              placeholder="Digite aqui..."
            ></v-text-field>
          </v-col>
        </v-row>

        <v-alert
          v-if="showAlert"
          dense
          border="left"
          color="primary"
          text
          type="info"
          class="mt-8 mb-0 text-center"
        >
          Por favor, preencha <strong>todos</strong> os campos acima para
          efetuar a ação!
        </v-alert>

        <v-col cols="12" class="vc-cadaster-form-footer pa-0 text-center mt-5">
          <v-btn @click="saveDependent" depressed color="primary">
            <div class="vc-text-bold">cadastrar</div>
          </v-btn>
        </v-col>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import ls from 'local-storage';
export default {
  name: 'form-dependent',
  props: {
    patient_id: {
      type: String,
      default: '',
    },
    user_id: {
      type: String,
      default: '',
    },
    company_id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      step: 1,
      valid: true,
      show1: false,
      showAlert: false,
      rules: {
        required: (value) => !!value || 'Campo obrigatório!',
        min: (v) => v.length >= 8 || 'Mínimo de 6 caracteres',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'E-mail inválido';
        },
      },
      genders: [
        { name: 'Masculino', slug: 'M' },
        { name: 'Feminino', slug: 'F' },
      ],
      modelDependent: {
        user_id: '',
        people: {
          name: '',
          cpf: '',
          rg: '',
          birth_date: '',
          email: '',
          gender: '',
          dependent_id: '',
          phone1: '',
          phone2: '',
        },
        patient: {
          num_sus: '',
        },
        company_id: '',
      },
      modelDependentDefault: {
        user_id: '',
        people: {
          name: '',
          cpf: '',
          rg: '',
          birth_date: '',
          email: '',
          gender: '',
          dependent_id: '',
        },
        patient: {
          phone1: '',
          phone2: '',
          num_sus: '',
        },
        company_id: '',
      },
    };
  },
  methods: {
    saveDependent() {
      this.showAlert = false;
      if (this.$refs.dependentForm.validate()) {
        this.modelDependent.company_id = this.company_id;
        this.modelDependent.user_id = this.user_id;
        this.modelDependent.people.dependent_id = ls.get('peopleId');
        this.formatCpf();
        this.formatDate();
        this.$store
          .dispatch('savePatient', this.modelDependent)
          .then((resp) => {
            if (resp) {
              this.$emit('getDependent');
              this.modelDependent = this.modelDependentDefault;
              this.closeDialog();
            }
          })
          .catch((error) => {
            var messageError = '';
            switch (error.response.data.message) {
              case 'this email is already registered':
                messageError = 'Email já cadastrado.';
                break;
              case 'this cpf is already registered':
                messageError = 'Cpf já cadastrado.';
                break;
              default:
                messageError = 'Erro ao cadastrar dependente.';
            }
            this.$store.commit('SET_NOTIFICATION', {
              type: 'error',
              message: messageError,
            });
          });
      } else {
        this.showAlert = true;
      }
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    formatCpf() {
      this.modelDependent.people.cpf = this.modelDependent.people.cpf.replaceAll(
        '.',
        ''
      );
      this.modelDependent.people.cpf = this.modelDependent.people.cpf.replace(
        '-',
        ''
      );
    },
    formatDate() {
      this.modelDependent.people.birth_date = this.modelDependent.people.birth_date.replaceAll(
        '/',
        '-'
      );
      this.modelDependent.people.birth_date = this.modelDependent.people.birth_date
        .split('-')
        .reverse()
        .join('-');
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #fff; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #a7a7a7; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: solid 3px #fff;
  // border: 3px solid orange; /* creates padding around scroll thumb */
}
</style>
