import { doctorDao } from '@/service'

export default {
    state: {},
    mutations: {},
    actions: {
        getAllDoctors(_, payload) {
            return new Promise((resolve, reject) => {
                doctorDao.getAllDoctors(payload)
                    .then(resp => {
                        resolve(resp.data.data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        getDoctor(_, payload) {
            return new Promise((resolve, reject) => {
                doctorDao.getDoctor(payload)
                    .then(resp => {
                        resolve(resp.data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
    },
    getters: {}
}
