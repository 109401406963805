import Vue from 'vue'
import Vuex from 'vuex'

// Modules
// import app from './app'
// import appConfig from './app-config'
// import verticalMenu from './vertical-menu'

// System modules
import moduleAuth from './auth'
import moduleDoctor from './doctor'
import modulePatient from './patient'
import moduleSchedule from './schedule'
import moduleSpecialty from './specialty'
import moduleConvenant from './convenant'
import moduleIbge from './ibgeStatesAndCities'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: moduleAuth,
    patient: modulePatient,
    doctor: moduleDoctor,
    schedule: moduleSchedule,
    specialty: moduleSpecialty,
    convenant: moduleConvenant,
    ibge: moduleIbge,
  },
  strict: process.env.DEV,
})
