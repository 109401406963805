<template>
  <div class="vc-error-404 vc-w-100 vc-column-center">
    <img
      width="130px"
      src="@/assets/images/logo-vclinic_1.png"
      alt=""
      class="mb-4"
    />
    <div class="vc-font-20 vc-text-bold vc-text-primary">
      Página não encontrada!
    </div>
    <div class="vc-font-14 vc-text-primary mb-1">
      Você acessou uma página que não existe!
    </div>
    <img
      width="25%"
      src="@/assets/images/error-404-art.svg"
      class="vc-error-404-art"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: 'error-404',
};
</script>
