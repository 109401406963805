import http from '../apiAuth'
import httpAdm from '../api'

let cadaster = (data) => {
    return http.post('patients', data)
}

let login = (data) => {
    return http.post('auth', data)
}
let changePassword = (data, token) => {
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  return http.post('changepassword', data, config);
};
let verifyHashRecoveryPassword = (data) => {
  return http.post('verifyhash', data);
};

let socialLogin = () => {
    return http.get('auth/social')
}

let currentUser = () => {
    return http.get('user')
}

let recoveryPassword = (data) => {
  return http.post(`infopasswordrecovery`, data);
};

/// company
let getCompany = (company_id) => {
  return httpAdm.get(`companies/${company_id}`);
};

export default {
  cadaster,
  login,
  socialLogin,
  currentUser,
  recoveryPassword,
  getCompany,
  verifyHashRecoveryPassword,
  changePassword,
};