import { scheduleDao } from '@/service'

export default {
    state: {
        schedules: [],
        scheduleTypeSelected: {},
        currentSchedule: {},
        currentConvenant: null,
        currentSpecialty: {},
        currentScheduleCompany: {},
        currentDoctor: {},
        currentPatient: {},
    },
    mutations: {
        SET_ALL_SCHEDULES(state, payload) {
            state.schedules = payload
        },
        SET_CURRENT_SCHEDULE(state, payload) {
            state.currentSchedule = payload
        },
        SET_SCHEDULE_TYPE(state, payload) {
            state.scheduleTypeSelected = payload
        },
        SET_CURRENT_CONVENANT(state, payload) {
            state.currentConvenant = payload
        },
        SET_CURRENT_SPECIALTY(state, payload) {
            state.currentSpecialty = payload
        },
        SET_CURRENT_SCHEDULE_COMPANY(state, payload) {
            state.currentScheduleCompany = payload
        },
        SET_CURRENT_DOCTOR(state, payload) {
            state.currentDoctor = payload
        },
        SET_CURRENT_PATIENT(state, payload) {
            state.currentPatient = payload
        },
        SET_SCHEDULE_DATA_NULL(state) {
            state.currentConvenant = null
            state.currentSpecialty = {}
            state.currentScheduleCompany = {}
            state.currentDoctor = {}
            state.currentPatient = {}
        }
    },
    actions: {
        getAllDoctorSchedule({ commit }, payload) {
            return new Promise((resolve, reject) => {
                scheduleDao.getAllDoctorSchedule(payload)
                    .then(resp => {
                        commit('SET_ALL_SCHEDULES', resp.data)
                        resolve(resp.data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        getScheduleTypes() {
            return new Promise((resolve, reject) => {
                scheduleDao.getScheduleTypes()
                    .then(resp => {
                        resolve(resp.data.data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        getScheduleList(_, payload) {
            return new Promise((resolve, reject) => {
                scheduleDao.getScheduleList(payload)
                    .then(resp => {
                        resolve(resp.data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        savePatientSchedule(_, payload) {
            return new Promise((resolve, reject) => {
                scheduleDao.savePatientSchedule(payload)
                    .then(resp => {
                        resolve(resp.data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        getPatientSchedule(_, payload) {
            return new Promise((resolve, reject) => {
                scheduleDao.getPatientSchedule(payload)
                    .then(resp => {
                        resolve(resp.data.data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        getScheduleAvaliable(_, payload) {
            return new Promise((resolve, reject) => {
                scheduleDao.getScheduleAvaliable(payload)
                    .then(resp => {
                        resolve(resp.data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        getAllPatientSchedule(_, payload) {
            return new Promise((resolve, reject) => {
                scheduleDao.getAllPatientSchedule(payload)
                    .then(resp => {
                        resolve(resp.data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        removePatientSchedule(_, payload) {
            return new Promise((resolve, reject) => {
                scheduleDao.removePatientSchedule(payload)
                    .then(resp => {
                        resolve(resp.data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        updatePatientSchedule(_, payload) {
            return new Promise((resolve, reject) => {
                scheduleDao.updatePatientSchedule(payload)
                    .then(resp => {
                        resolve(resp.data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
        reserveSchedule(_, payload) {
            return new Promise((resolve, reject) => {
                scheduleDao.reserveSchedule(payload)
                    .then(resp => {
                        resolve(resp.data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
    },
    getters: {
        schedules: state => state.schedules,
        currentSchedule: state => state.currentSchedule,
        scheduleTypeSelected: state => state.scheduleTypeSelected,
        currentConvenant: state => state.currentConvenant,
        currentSpecialty: state => state.currentSpecialty,
        currentScheduleCompany: state => state.currentScheduleCompany,
        currentDoctor: state => state.currentDoctor,
        currentPatient: state => state.currentPatient,
    },
}
