import { convenantDao } from '@/service'

export default {
    state: {},
    mutations: {},
    actions: {
        getAllConvenants(_, payload) {
            return new Promise((resolve, reject) => {
                convenantDao.getAllConvenants(payload)
                    .then(resp => {
                        resolve(resp.data.data)
                    }).catch(err => {
                        reject(err)
                    })
            })
        },
    },
    getters: {}
}
