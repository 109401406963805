import http from './api';

const memoryCache = new Map();

class RequestMade {
    constructor({ useCache } = {}) {
        this.requestsMade = new Map();
        this.useCache = useCache;
    }

    set(url, params, response) {
        const key = this.createKey(url, params);

        this.requestsMade.set(key, response);
        memoryCache.set(key, response);
    }

    get(url, params) {
        const key = this.createKey(url, params);

        if (this.useCache && memoryCache.has(key)) {
            return memoryCache.get(key);
        }

        return this.requestsMade.get(this.createKey(url, params));
    }

    has(url, params) {
        const key = this.createKey(url, params);

        return this.useCache && memoryCache.has(key) || this.requestsMade.has(key);
    }

    createKey(url, params) {
        return `${url} ${JSON.stringify(params)}`;
    }
}

/**
* Cria a instância de Paginator vinculada a uma configuração
* @constructs Paginator
* @param {Object} config
* @example
* new Paginator({
*   url: '/api/v1/algum/endpoint',
*   config: {
*     // configuração do Axios (opcional)
*   }
* })
*/
export default class Paginator {

    constructor(config) {
        if (!config || !config.url) {
            throw Error('É necessário especificar uma URL!');
        }

        config.useCache = config.useCache === true;
        config.serializer = config.serializer || ((data) => Promise.resolve(data));

        config.config = config.config || {};
        config.config.params = config.config.params || {};

        // if (config.config.params.page) config.config.params.page = config.config.params.page;

        this.config = config;

        this.requestsMade = new RequestMade({ useCache: config.useCache });
    }

    /**
     * Retorna os dados da página atual definida no Paginator.
     * @returns {Promise}
     * @instance
     * @memberof Paginator
     */
    getPage() {
        return this._doRequest();
    }

    /**
     * Método que busca todas as páginas com chamadas sequenciais, entregando o resultado
     * assim que cada página é retornada do endpoint
     * @param {Function} cb Callback chamado quando Paginator retorna uma nova página 
     * @instance
     * @memberof Paginator
     */
    getAllPages(cb) {
        this.getPage()
            .then(res => {
                cb(res);

                if (this.hasNextPage()) {
                    this.nextPage();
                    this.getAllPages(cb);
                }
            });
    }

    /**
     * Avança o Paginator para a próxima página, se existir
     * @instance
     * @memberof Paginator
     */
    nextPage() {
        this.config.config.params.page = this.config.config.params.page + 1;
    }

    /**
    * Muda o Paginator para a página anterior
    * @instance
    * @memberof Paginator
    */
    previousPage() {
        if (this.config.config.params.page === 1) {
            return;
        }

        this.config.config.params.page = this.config.config.params.page - 1;
    }

    /**
    * Verifica se o Paginator contém uma próxima página, baseando-se na página atual
    * @returns {boolean}
    * @instance
    * @memberof Paginator
    */
    hasNextPage() {
        const res = this.requestsMade.get(this.config.url, this.config.config.params);

        if (!res) {
            return false;
        }

        return Boolean(res.data.next);
    }

    /**
    * Verifica se o Paginator contém uma página anterior, baseando-se na página atual
    * @returns {boolean}
    * @instance
    * @memberof Paginator
    */
    hasPreviousPage() {
        const res = this.requestsMade.get(this.config.url, this.config.config.params);

        if (!res) {
            return false;
        }

        return Boolean(res.data.previous);
    }

    _doRequest() {
        const res = this.requestsMade.get(this.config.url, this.config.config.params);

        if (res) {
            return Promise.resolve(res);
        }

        return http.get(this.config.url, this.config.config)
            .then(res => this.config.serializer(res))
            .then(res => {
                this.requestsMade.set(this.config.url, this.config.config.params, res);
                return res;
            });
    }

}