<template>
  <v-dialog
    class="auth-dialog-index"
    v-model="authModal"
    persistent
    max-width="450px"
  >
    <v-card class="vcLightGrey pt-4 pl-4 pb-4 pr-4">
      <v-row class="vc-auth-dialog vc-w-100 ma-0">
        <div @click="closeDialog" class="vc-auth-dialog-close">
          <v-icon color="vcDarkGrey"> mdi-close </v-icon>
        </div>
        <v-col cols="12" class="text-center">
          <div class="vc-font-20 vc-text-bold">Login</div>
        </v-col>
        <v-col cols="12" class="vc-auth-form-col pa-0 mt-4">
          <v-form
            class="vc-w-100 vc-auth-form"
            ref="loginForm"
            v-model="valid"
            lazy-validation
          >
            <v-row class="ma-0">
              <v-col cols="12" class="vc-auth-form-item-col pa-0">
                <v-text-field
                  v-model="modelUser.email"
                  dense
                  class="vc-w-100"
                  v-on:keyup.enter="login"
                  label="Digite o email"
                  prepend-inner-icon="mdi-account"
                  :rules="[rules.required, rules.email]"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" class="vc-auth-form-item-col pa-0 mt-2">
                <v-text-field
                  v-model="modelUser.password"
                  dense
                  class="vc-w-100"
                  label="Digite a senha"
                  v-on:keyup.enter="login"
                  prepend-inner-icon="mdi-lock"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required]"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="text-end pa-0">
                <a class="vc-font-12" @click="forgotPassword"
                  >Esqueceu sua senha?</a
                >
              </v-col>

              <v-col cols="12" class="text-center pa-0 mt-5">
                <v-btn @click="login" keyup.enter depressed color="primary">
                  <div class="vc-text-bold">login</div>
                </v-btn>
              </v-col>
              <v-col cols="12" class="vc-row-center">
                <div class="vc-font-12 mt-2 vc-text-darkGrey">
                  Não possui cadastro ainda?
                  <a class="vc-font-12" @click="toCadaster">cadastre-se</a>
                </div>
              </v-col>
              <v-col cols="12" class="vc-row-center">
                <div class="vc-font-12 vc-text-darkGrey text-center">
                  Ao se cadastrar, um <strong>e-mail</strong> com as <strong>credenciais de login</strong> será enviado!
                </div>
              </v-col>

              <!--               <v-col cols="12" class="vc-auth-or pa-0 mt-3 mb-3">
                <div class="vc-divider-grey"></div>
                <div class="ml-3 mr-3 vc-text-grey">ou</div>
                <div class="vc-divider-grey"></div>
              </v-col> -->

              <!-- <v-col class="vc-auth-social-login pa-0 mb-4">
                <v-btn class="mr-3" fab small color="white">
                  <v-icon size="25px" dark color="red"> mdi-google </v-icon>
                </v-btn>
                <v-btn @click="socialLogin" size="25px" fab small color="white">
                  <v-icon dark color="primary"> mdi-facebook </v-icon>
                </v-btn>
              </v-col> -->
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import ls from 'local-storage';
export default {
  name: 'auth-dialog',
  data() {
    return {
      valid: true,
      show1: false,
      rules: {
        required: (value) => !!value || 'Campo obrigatório!',
        min: (v) => v.length >= 8 || 'Mínimo de 6 caracteres',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'E-mail inválido';
        },
      },
      modelUser: {
        email: '',
        password: '',
      },
    };
  },
  computed: {
    authModal() {
      return this.$store.getters.authModal;
    },
    cadasterModal() {
      return this.$store.getters.cadasterModal;
    },
    forgotpswdModal() {
      return this.$store.getters.forgotpswdModal;
    },
    currentCompany() {
      return this.$store.getters.currentCompany;
    },
  },
  methods: {
    login() {
      if (this.$refs.loginForm.validate()) {
        this.$store
          .dispatch('getPatient', {
            email: this.modelUser.email,
            company_id: this.currentCompany.id,
          })
          .then((resp) => {
            this.$store
              .dispatch('login', this.modelUser)
              .then(() => {
                if (resp.is_just_people || resp.no_patient_relation) {
                  ls.set('t', '');
                  this.$store.commit('UNSET_USER');
                  this.$store.commit('SET_INFOS_PEOPLE', resp);
                  this.$store.commit('SET_CADASTER_MODAL', true);
                  this.$store.commit('SET_NOTIFICATION', {
                    type: 'error',
                    message: 'Conclua seu cadastro antes de fazer login',
                  });
                } else {
                  ls.set('patientId', resp.id);
                  this.$store.commit('SET_PATIENT_ID', resp.id);
                  ls.set('peopleId', resp.people_id);
                  this.closeDialog();
                  this.modelUser.email = '';
                  this.modelUser.password = '';
                  this.valid = true;
                  this.$store.commit('SET_NOTIFICATION', {
                    type: 'success',
                    message: 'Login efetuado com sucesso!',
                  });
                }
              })
              .catch((error) => {
                if (error.response.data[0].message == 'Invalid user password') {
                  this.$store.commit('SET_NOTIFICATION', {
                    type: 'error',
                    message: 'Email ou senha incorretos!',
                  });
                } else {
                  this.$store.commit('SET_NOTIFICATION', {
                    type: 'error',
                    message: 'Erro ao efetuar o login',
                  });
                }
              });
          })
          .catch((error) => {
            if (error.response.data.message == 'people not exist') {
              this.$store.commit('SET_NOTIFICATION', {
                type: 'error',
                message:
                  'Paciente Não cadastrado, cadastre o paciente primeiro',
              });
            } else if (error.response.data.message == 'patient not exist') {
              this.$store.commit('SET_NOTIFICATION', {
                type: 'error',
                message:
                  'Paciente Não cadastrado, cadastre o paciente primeiro',
              });
            }
            this.$store.commit('SET_CADASTER_MODAL', true);
          });
      }
    },
    socialLogin() {
      this.$store.dispatch('socialLogin').then((resp) => {
        console.log(resp);
      });
    },
    closeDialog() {
      this.$store.commit('SET_AUTH_MODAL', false);
      this.modelUser.email = '';
      this.modelUser.password = '';
      this.valid = true;
    },
    toCadaster() {
      this.$store.commit('SET_CADASTER_MODAL', true);
      this.closeDialog();
    },
    forgotPassword() {
      this.$store.commit('SET_FORGOTPSWD_MODAL', true);
      this.closeDialog();
    },
  },
};
</script>
