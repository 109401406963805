<template>
  <div>
    <v-card
      width="100%"
      class="vc-card-consult pa-3"
      style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5) !important;"
      dark
      elevation="0"
    >
      <div class="vc-font-20">
        Consulta: <strong>{{ scheduleType.name }}</strong>
      </div>

      <div class="vc-divider mt-1 mb-1"></div>

      <v-card-subtitle v-if="scheduleType.description" class="pa-0">
        <div>Informações sobre a consulta</div>
      </v-card-subtitle>

      <v-card-actions
        :class="scheduleType.description ? 'pa-0 mt-3' : 'pa-0 mt-2'"
      >
        <v-btn color="primaryLight">
          <div @click="setScheduleType" class="vc-text-primary vc-text-bold">
            selecionar
          </div>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// import { format } from 'date-fns';
import ls from 'local-storage';

export default {
  name: 'card-consult',
  props: {
    scheduleType: { type: Object, default: () => {} },
  },
  methods: {
    setScheduleType() {
      ls.set('currentScheduleType', this.scheduleType.id);
      this.$store.commit('SET_SCHEDULE_TYPE', this.scheduleType.id);
      this.$router.push({ name: 'scheduling' });
    },
  },
};
</script>
